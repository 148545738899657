import { useMemo } from 'react'
import UserProfileIcon from '@ancon/wildcat-ui/shared/icons/circle-user-outline.svg'
import CompanyIcon from '@ancon/wildcat-ui/shared/icons/company-outline.svg'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import {
  clientContextCompanyIdSelector,
  clientContextIsAnonymousCustomerSelector,
  clientContextProfileNameSelector,
} from '../../../../clientContext/store/clientContextSelectors'
import Button from '../../../../app/components/Button'
import { signOut } from '../../../../auth/store/authThunks'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { authSignOutPendingSelector } from '../../../../auth/store/authSelectors'
import Dropdown from '../../../../app/components/dropdown/Dropdown'
import { DropdownButtonProps } from '../../../../app/components/dropdown/DropdownButton'
import DropdownIcon from '../../../../app/components/dropdown/DropdownIcon'
import { AppRoutes } from '../../../../app/constants'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import { setAuthGuestCustomerOnboardingModalVisible } from '../../../../auth/store/authSlice'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'

import styles from './NavbarUserProfile.module.scss'

enum ProfileOptions {
  MyOrders = 'MyOrders',
  MyAccount = 'MyAccount',
  SignOut = 'SignOut',
  GuestOnBoarding = 'GuestOnBoarding',
}

function ProfileDropdownButton({
  className,
  isOpen,
  onToggle,
}: DropdownButtonProps) {
  const signOutPending = useAppSelector(authSignOutPendingSelector)

  const profileName = useAppSelector(clientContextProfileNameSelector)

  const companyId = useAppSelector(clientContextCompanyIdSelector)

  const isOutletDetailsPage = useRouteMatch([`${AppRoutes.Outlet}/`])

  return (
    <Button
      size="large"
      variant="secondary"
      onClick={onToggle}
      disabled={signOutPending}
      className={clsx(styles.profile, className)}
    >
      {companyId ? <CompanyIcon /> : <UserProfileIcon />}

      <span
        className={clsx(styles.accountName, {
          [styles.outletDetailsPage]: isOutletDetailsPage,
        })}
      >
        {profileName}
      </span>
      <DropdownIcon isOpen={isOpen} />
    </Button>
  )
}

export default function NavbarUserProfile() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isAnonymousCustomer = useAppSelector(
    clientContextIsAnonymousCustomerSelector,
  )

  const { t } = useTranslation('common')

  const options = useMemo(() => {
    const navBarOptions = [
      {
        label: t('components.navBar.profile.myOrders'),
        value: ProfileOptions.MyOrders,
      },
    ]

    if (!isAnonymousCustomer) {
      navBarOptions.push({
        label: t('components.navBar.profile.myAccount'),
        value: ProfileOptions.MyAccount,
      })
    } else {
      navBarOptions.push({
        label: t(
          isAppWhiteLabeled()
            ? 'components.navBar.auth.createProfile'
            : 'components.navBar.auth.createAnconProfile',
        ),
        value: ProfileOptions.GuestOnBoarding,
      })
    }

    navBarOptions.push({
      label: t(
        isAnonymousCustomer
          ? 'components.navBar.profile.exitSession'
          : 'components.navBar.profile.signOut',
      ),
      value: ProfileOptions.SignOut,
    })

    return navBarOptions
  }, [isAnonymousCustomer, t])

  function handleClickOption(option: string) {
    switch (option) {
      case ProfileOptions.MyOrders:
        router.push(AppRoutes.Orders)
        break

      case ProfileOptions.MyAccount:
        router.push(AppRoutes.Account)
        break

      case ProfileOptions.SignOut:
        dispatch(signOut())
        break

      case ProfileOptions.GuestOnBoarding:
        dispatch(setAuthGuestCustomerOnboardingModalVisible(true))
        break

      default:
        break
    }
  }

  return (
    <Dropdown
      CustomDropdownButton={ProfileDropdownButton}
      popupPlacement="top-end"
      onClickOption={handleClickOption}
      popupClassName={clsx(styles.navUserProfileDropdown, {
        [styles.guestCustomer]: isAnonymousCustomer,
      })}
      options={options}
    />
  )
}
