import { useRef } from 'react'
import { batch } from 'react-redux'
import { CheckoutRequestItem } from '@ancon/wildcat-utils/checkout/types'
import { buildCheckoutRequestItem } from '@ancon/wildcat-utils/checkout'
import { createCodedError } from '@ancon/wildcat-utils/error'
import { ErrorCode, OutletState } from '@ancon/wildcat-types'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'

import {
  ConfigureProductFormState,
  ConfigureProductUpsellGroupFormState,
} from '../types'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  productsUpSellGroupIsOutOfStockSelector,
  productsUpsellGroupHasProductsSelector,
} from '../store/productSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  setProductDetailsModalVisible,
  setProductUpsellModalVisible,
} from '../store/productSlice'
import { checkoutCurrentCheckoutSelectedItemIdSelector } from '../../checkout/store/checkoutSelectors'
import useAppStore from '../../../store/hooks/useAppStore'
import {
  outletListItemSelector,
  outletSelectedOutletIdSelector,
} from '../../outlet/store/outletSelector'
import {
  addCheckoutItem,
  updateCheckoutItem,
} from '../../checkout/store/checkoutThunks'
import { setSelectedCheckoutItemId } from '../../checkout/store/checkoutSlice'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { appShowError } from '../../app/store/appSlice'
import { ErrorModalType } from '../../app/types'
import { getTranslatedError } from '../../app/utils/getTranslatedError'

import ProductDetailsModal from './ProductDetailsModal'
import ProductUpsellModal from './ProductUpsellModal'

export default function OrderProduct() {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const hasUpsellGroups = useAppSelector(productsUpsellGroupHasProductsSelector)
  const productUpsellIsOutOfStock = useAppSelector(
    productsUpSellGroupIsOutOfStockSelector,
  )
  const outlet = useAppSelector(outletListItemSelector)

  const productDetailsFormValuesRef = useRef<
    ConfigureProductFormState | undefined
  >(undefined)

  function clearSelectedCheckoutItemId() {
    const selectedCheckoutItemId =
      checkoutCurrentCheckoutSelectedItemIdSelector(store.getState())

    if (selectedCheckoutItemId) {
      dispatch(setSelectedCheckoutItemId(null))
    }
  }

  async function handleAddToItemsToCart(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    upsellFormState?: ConfigureProductUpsellGroupFormState,
  ) {
    const selectedOutletId = outletSelectedOutletIdSelector(store.getState())
    const selectedCheckoutItemId =
      checkoutCurrentCheckoutSelectedItemIdSelector(store.getState())

    if (productDetailsFormValuesRef.current) {
      const items: CheckoutRequestItem[] = []

      items.push(buildCheckoutRequestItem(productDetailsFormValuesRef.current))

      if (upsellFormState && upsellFormState.selectedUpsellGroups?.length) {
        const { selectedUpsellGroups, upsellGroupId } = upsellFormState
        const upsellSelectedProducts = selectedUpsellGroups.reduce<
          CheckoutRequestItem[]
        >((acc, product) => {
          if (product.selectedCount > 0) {
            acc.push({
              variantId: product.variantId,
              quantity: product.selectedCount,
              modifications: [],
              upsellGroupId,
            })
          }
          return acc
        }, [])

        items.push(...upsellSelectedProducts)
      }

      const { outletId } = productDetailsFormValuesRef.current
      try {
        if (selectedCheckoutItemId) {
          await dispatch(
            updateCheckoutItem({
              item: items[0],
              checkoutItemId: selectedCheckoutItemId,
              outletId: outletId || selectedOutletId,
            }),
          )
        } else {
          await dispatch(
            addCheckoutItem({
              items,
              outletId: outletId || selectedOutletId,
            }),
          )
        }
        productDetailsFormValuesRef.current = undefined
      } catch (err) {
        // TODO: handle error
      }

      clearSelectedCheckoutItemId()
    }

    productDetailsFormValuesRef.current = undefined
  }

  async function handleProductDetailsModalSubmit(
    values: ConfigureProductFormState,
  ) {
    if (
      outlet &&
      outlet.state === OutletState.Closed &&
      !isOutletAcceptingPreOrders(outlet.orderFormatSettings)
    ) {
      const locale = appLanguageSelector(store.getState())
      const error = await getTranslatedError(
        createCodedError(
          'Restaurant not open yet',
          ErrorCode.RequestedOutletIsNotOpen,
        ),
        undefined,
        locale,
      )
      dispatch(
        appShowError({
          ...error,
          errorModalType: ErrorModalType.OutletNotAvailableForOrders,
        }),
      )
      return
    }
    productDetailsFormValuesRef.current = values

    const selectedCheckoutItemId =
      checkoutCurrentCheckoutSelectedItemIdSelector(store.getState())

    if (
      hasUpsellGroups &&
      !productUpsellIsOutOfStock &&
      !selectedCheckoutItemId
    ) {
      batch(() => {
        dispatch(setProductUpsellModalVisible(true))
        dispatch(setProductDetailsModalVisible(false))
      })
    } else {
      dispatch(setProductDetailsModalVisible(false))
      await handleAddToItemsToCart()
    }
  }

  async function handleUpsellModalSubmit(
    values: ConfigureProductUpsellGroupFormState,
  ) {
    const { selectedUpsellGroups } = values
    const selectedUpsellCount = selectedUpsellGroups.reduce(
      (acc, currentUpSell) => acc + currentUpSell.selectedCount,
      0,
    )
    const hasSelections = selectedUpsellCount > 0
    if (productDetailsFormValuesRef.current) {
      dispatch(setProductUpsellModalVisible(false))
      const shouldAddUpsell = hasSelections
      await handleAddToItemsToCart(shouldAddUpsell ? values : undefined)
    }
  }

  function handleUpsellModalDismiss() {
    dispatch(setProductUpsellModalVisible(false))
    clearSelectedCheckoutItemId()
  }

  function handleProductDetailsModalDismiss() {
    dispatch(setProductDetailsModalVisible(false))
    clearSelectedCheckoutItemId()
  }

  return (
    <>
      <ProductDetailsModal
        onSubmit={handleProductDetailsModalSubmit}
        onDismiss={handleProductDetailsModalDismiss}
      />
      <ProductUpsellModal
        onSubmit={handleUpsellModalSubmit}
        onDismiss={handleUpsellModalDismiss}
      />
    </>
  )
}
