import { HTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'

import InteractiveDiv from '../InteractiveDiv'

import styles from './Card.module.scss'

interface CardProps
  extends PropsWithChildren<Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>> {
  onClick?: () => void
}

export default function Card({
  children,
  className,
  onClick,
  ...otherProps
}: CardProps) {
  return (
    <InteractiveDiv
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      className={clsx(styles.container, className)}
      onClick={onClick}
    >
      {children}
    </InteractiveDiv>
  )
}
