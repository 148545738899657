import { useRef, useEffect } from 'react'

export default function useMounted() {
  const ref = useRef<boolean>(false)

  useEffect(() => {
    ref.current = true

    return () => {
      ref.current = false
    }
  }, [])

  return ref.current
}
