import useTranslation from 'next-translate/useTranslation'
import { OrderFormat } from '@ancon/wildcat-types'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { useRouter } from 'next/router'

import Modal from '../../app/components/modal/Modal'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  checkoutCartMinimumDifferenceAmountSelector,
  checkoutCurrentCheckoutOutletListItemSelector,
  checkoutCurrentCheckoutSubTotalSelector,
  checkoutIsMinimumOrderModalVisibleSelector,
  checkoutOutletNameSelector,
} from '../store/checkoutSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { checkoutSetIsVisibleMinimumOrderModal } from '../store/checkoutSlice'
import BodyText from '../../app/components/BodyText'
import Button from '../../app/components/Button'
import { AppRoutes } from '../../app/constants'

import styles from './MinimumOrderModal.module.scss'

export default function MinimumOrderModal() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isOpen = useAppSelector(checkoutIsMinimumOrderModalVisibleSelector)
  const checkoutOutlet = useAppSelector(
    checkoutCurrentCheckoutOutletListItemSelector,
  )
  const checkoutOutletName = useAppSelector(checkoutOutletNameSelector)
  const checkoutSubTotal = useAppSelector(
    checkoutCurrentCheckoutSubTotalSelector,
  )!
  const checkoutDifference = useAppSelector(
    checkoutCartMinimumDifferenceAmountSelector,
  )!
  const deliverySettings = checkoutOutlet?.orderFormatSettings.find(
    setting => setting.orderFormat === OrderFormat.Delivery,
  )
  const outletMinimumOrderAmount = deliverySettings?.minimumOrder?.amount ?? 0

  function handleCloseModal() {
    dispatch(checkoutSetIsVisibleMinimumOrderModal(false))
  }

  function handleOnPressProceed() {
    dispatch(checkoutSetIsVisibleMinimumOrderModal(false))
    router.push({ pathname: AppRoutes.Checkout })
  }

  return (
    <Modal
      title={t('minimumOrderModal.title', {
        amount: getFormattedCurrency(
          outletMinimumOrderAmount,
          deliverySettings?.minimumOrder?.currency,
        ),
      })}
      isOpen={isOpen}
      onClose={handleCloseModal}
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <BodyText color="body-1">
          {t('minimumOrderModal.message', {
            outletName: checkoutOutletName,
            amount: getFormattedCurrency(
              checkoutSubTotal.amount,
              checkoutSubTotal.currency,
            ),
            differenceAmount: getFormattedCurrency(
              checkoutDifference?.amount,
              checkoutDifference?.currency,
            ),
          })}
        </BodyText>
      </div>
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={handleCloseModal}>
          {t('minimumOrderModal.goBack')}
        </Button>
        <Button onClick={handleOnPressProceed}>
          {t('minimumOrderModal.proceed')}
        </Button>
      </div>
    </Modal>
  )
}
