import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  ClientContextResponse,
  CustomerUserDetails,
} from '@ancon/wildcat-types'

import { signOut } from '../../auth/store/authThunks'
import { ClientContextState } from '../types'
import { fetchCustomerAddressesList } from '../../account/store/accountThunks'

import { fetchClientContext, fetchCustomerDetails } from './clientContextThunks'

const clientContextInitialState: ClientContextState = {
  clientContextFetchPending: false,
  clientContextFetchError: null,
  clientContext: null,

  customerFetchPending: false,
  customerFetchError: null,
  customer: null,
}

const clientContextSlice = createSlice({
  name: 'clientContext',
  initialState: clientContextInitialState,
  reducers: {},
  extraReducers: builder => {
    // Fetch clientContext
    builder.addCase(fetchClientContext.pending, state => {
      state.clientContextFetchPending = true
      state.clientContextFetchError = null
    })
    builder.addCase(
      fetchClientContext.fulfilled,
      (state, action: PayloadAction<ClientContextResponse | null>) => {
        state.clientContext = action.payload
        state.clientContextFetchPending = false
      },
    )
    builder.addCase(fetchClientContext.rejected, (state, action) => {
      state.clientContextFetchPending = false
      state.clientContextFetchError = action.error.message || null
    })

    // Fetch userDetails
    builder.addCase(fetchCustomerDetails.pending, state => {
      state.customerFetchPending = true
      state.customerFetchError = null
    })
    builder.addCase(
      fetchCustomerDetails.fulfilled,
      (state, action: PayloadAction<CustomerUserDetails | null>) => {
        state.customer = action.payload
        state.customerFetchPending = false
      },
    )
    builder.addCase(fetchCustomerDetails.rejected, (state, action) => {
      state.customerFetchPending = false
      state.customerFetchError = action.error.message || null
    })

    // Sign Out
    builder.addCase(signOut.fulfilled, () => clientContextInitialState)

    builder.addMatcher(
      isAnyOf(fetchCustomerAddressesList.fulfilled),
      (state, action) => {
        if (state.customer) {
          state.customer = {
            ...state.customer,
            addresses: action.payload.items,
          }
        }
      },
    )
  },
})

export default clientContextSlice.reducer
