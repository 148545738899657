import clsx from 'clsx'

import CartDrawer from '../../../checkout/components/CartDrawer'
import useCartDrawerOpen from '../../../checkout/hooks/useCartDrawerOpen'

import MainFooter from './MainFooter'
import MainNavbar from './navBar/MainNavbar'
import styles from './MainLayout.module.scss'
import MobileSideDrawer from './navBar/MobileSideDrawer'

function MainLayout({ children }: React.PropsWithChildren) {
  const isCartDrawerVisible = useCartDrawerOpen()

  return (
    <>
      <MainNavbar />
      <div
        className={clsx(styles.content, {
          [styles.drawerOpen]: isCartDrawerVisible,
        })}
      >
        {children}
      </div>
      <MainFooter />
      <CartDrawer />
      <MobileSideDrawer />
    </>
  )
}

export default MainLayout
