import { ServiceTimeKindType } from '@ancon/wildcat-types'

import { CheckoutReducerState } from '../types'

export const checkoutInitialState: CheckoutReducerState = {
  checkoutFilters: {
    orderFormat: null,
    serviceTime: { kind: ServiceTimeKindType.AsSoonAsPossible, time: '' },
    salesLocation: null,
    delivery: null,
  },

  currentCheckoutOutletId: null,
  currentCheckoutOutletListDetails: null,

  currentCheckoutId: null,
  currentCheckoutDetails: null,
  currentCheckoutSelectedCheckoutItemId: null,
  currentCheckoutDetailsFetchPending: false,
  currentCheckoutPaymentPending: false,
  checkoutOutletListItemFetchPending: false,

  checkoutWayOfPaymentsFetchPending: false,
  adyenPaymentMethodsFetchPending: false,
  paymentSettingsFetchPending: false,

  checkoutAddItemPending: false,
  checkoutUpdatePending: false,
  checkoutApplyDiscountPending: false,
  checkoutUpdateItemQuantityPending: false,
  checkoutDeletePending: false,
  checkoutUpdateItemPending: false,
  checkoutValidatePending: false,
  cancelSelectedCheckoutPending: false,

  checkoutSummaryFetchPending: false,
  checkoutSummary: null,
  checkoutSummaryOutletListItem: null,

  isMinimumOrderModalVisible: false,
  isDiscountModalVisible: false,
  isCustomerInfoModalVisible: false,
  isVisibleDeleteOrderModal: false,

  checkoutStockErrorAdditionalData: null,

  checkoutPaymentAttempt: 0,

  checkoutStatusLastUpdatedAt: 0,
}
