/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { SalesLocationType, OrderFormat } from '@ancon/wildcat-types'

import { OrderConfigureFilterMode, OutletReducerState } from '../types'
import { InformativeError } from '../../app/types'

import {
  fetchOutletDetails,
  validateTableOrderUrl,
  fetchOutletListItem,
  validateTableNumber,
} from './outletThunks'
import { outletReducers } from './outletReducers'

const outletInitialState: OutletReducerState = {
  // Outlet details
  outletListItem: null,
  outletDetails: null,
  outletFilters: {
    orderFormat: null,
    serviceTime: null,
    salesLocation: null,
    delivery: null,
  },

  isOutletDetailsPending: false,

  // Modal
  orderConfigureFilterModal: OrderConfigureFilterMode.None,
  isVisibleOutletChangeWarningModal: false,
  isVisibleDeliveryLocationModal: false,
  isVisibleTableOrderConfigureModal: false,
  isVisibleOutletDetailsModal: false,
  isVisibleServiceTimeKindSelectionModal: false,

  // Validate table number
  outletTableNumberValidatePending: false,
  outletTableNumberValidateError: null,
}

const outletSlice = createSlice({
  name: 'outlet',
  initialState: outletInitialState,
  reducers: outletReducers,
  extraReducers: builder => {
    // Fetch outlet Details
    builder.addCase(fetchOutletListItem.fulfilled, (state, action) => {
      const { payload } = action
      state.outletListItem = payload
    })
    builder.addCase(fetchOutletDetails.pending, state => {
      state.isOutletDetailsPending = true
    })
    builder.addCase(fetchOutletDetails.fulfilled, (state, action) => {
      const { payload } = action
      state.outletDetails = payload
      state.isOutletDetailsPending = false
    })
    builder.addCase(fetchOutletDetails.rejected, state => {
      state.isOutletDetailsPending = false
    })
    builder.addCase(validateTableOrderUrl.fulfilled, (state, action) => {
      const { tableId, tableNumber } = action.payload
      if (!tableId || !tableNumber) return
      state.outletFilters = {
        ...state.outletFilters,
        orderFormat: OrderFormat.TableOrder,
        salesLocation: {
          type: SalesLocationType.Table,
          table: {
            id: tableId,
            number: tableNumber!,
          },
        },
      }
    })
    // Outlet Table Number Validating
    builder.addCase(validateTableNumber.pending, state => {
      state.outletTableNumberValidatePending = true
      state.outletTableNumberValidateError = null
    })
    builder.addCase(validateTableNumber.fulfilled, state => {
      state.outletTableNumberValidatePending = false
    })
    builder.addCase(validateTableNumber.rejected, (state, action) => {
      state.outletTableNumberValidatePending = false
      state.outletTableNumberValidateError = action.payload as InformativeError
    })
  },
})

export const {
  outletSetOrderConfigureFilterModal,
  outletSetOutletFilters,
  outletSetIsVisibleOutletChangeWarningModal,
  outletSetIsVisibleDeliveryLocationModal,
  outletSetIsVisibleTableOrderConfigureModal,
  outletClearTableNumberValidateError,
  outletSetIsVisibleOutletDetailsModal,
  outletClearOutletDetails,
  outletSetIsVisibleServiceTimeKindSelectionModal,
} = outletSlice.actions

export default outletSlice.reducer
