import React, { useState } from 'react'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { CheckoutItem, TicketItemStatus } from '@ancon/wildcat-types'
import { getCheckoutGroupedModifications } from '@ancon/wildcat-utils/checkout'
import DropDownIcon from '@ancon/wildcat-ui/shared/icons/arrow-drop-down.svg'
import clsx from 'clsx'
import { batch } from 'react-redux'
import TickIcon from '@ancon/wildcat-ui/shared/icons/tick.svg'
import useTranslation from 'next-translate/useTranslation'

import Button from '../../../app/components/Button'
import BodyText from '../../../app/components/BodyText'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  setProductDetailsModalVisible,
  setSelectedProductId,
} from '../../../product/store/productSlice'
import { setSelectedCheckoutItemId } from '../../store/checkoutSlice'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { checkoutCurrentCheckoutPendingSelector } from '../../store/checkoutSelectors'

import CartItemQuantityWidget from './CartItemQuantityWidget'
import styles from './CartItem.module.scss'

type CartItemProps = {
  cartItem: CheckoutItem
  showSeparator?: boolean
  omitQuantityModifier?: boolean
  omitTicketItemStatus?: boolean
  disableClick?: boolean
}

export default function CartItem({
  cartItem,
  showSeparator = false,
  omitQuantityModifier = false,
  omitTicketItemStatus = false,
  disableClick = false,
}: CartItemProps) {
  const { t } = useTranslation('checkout')
  const dispatch = useAppDispatch()
  const {
    id,
    product,
    quantity,
    totalPriceInclTax,
    discountAmountInclTax,
    modifications,
    ticketItem,
  } = cartItem
  const { hasVariants, name, variantName } = product

  const [itemQuantity, setItemQuantity] = useState(quantity)
  const [isModifyingQuantity, setIsModifyingQuantity] = useState(false)

  const groupedModifications = getCheckoutGroupedModifications(modifications)
  const checkoutPending = useAppSelector(checkoutCurrentCheckoutPendingSelector)

  const hasDiscount = discountAmountInclTax.amount > 0
  const isShowItemStatus = !omitTicketItemStatus && !!ticketItem

  function handleQuantityChange(changedQuantity: number) {
    setItemQuantity(changedQuantity)
  }

  function handleCartItemClick() {
    if (disableClick) return

    batch(() => {
      dispatch(setSelectedProductId(product.id))
      dispatch(setSelectedCheckoutItemId(id))
      dispatch(setProductDetailsModalVisible(true))
    })
  }

  function handleOpenQuantityWidget(
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    event.stopPropagation()
    setIsModifyingQuantity(true)
  }

  function handleCloseQuantityWidget() {
    setIsModifyingQuantity(false)
  }

  function getTicketItemStatus() {
    if (ticketItem?.status === TicketItemStatus.Ready) {
      return (
        <div className={styles.ticketStatus}>
          <BodyText color="body-3">{t('ready')}</BodyText>
        </div>
      )
    }

    if (ticketItem?.status === TicketItemStatus.Closed) {
      return (
        <div className={styles.pickedUpStatus}>
          <BodyText color="body-4">{t('pickedUp')}</BodyText>
          <TickIcon />
        </div>
      )
    }

    return null
  }

  return (
    <>
      {isShowItemStatus && getTicketItemStatus()}
      <InteractiveDiv
        className={clsx(styles.container, {
          [styles.selectedItemContainer]: isModifyingQuantity,
          [styles.container]: !isModifyingQuantity,
          [styles.hasItemStatus]: isShowItemStatus,
        })}
        tabIndex={0}
        onClick={handleCartItemClick}
        disabled={checkoutPending}
      >
        <div
          className={clsx(styles.itemQuantity, {
            [styles.quantityText]: omitQuantityModifier,
          })}
        >
          {omitQuantityModifier ? (
            <BodyText fontSize="1.6rem" className={styles.quantityText}>
              {itemQuantity}
            </BodyText>
          ) : (
            <Button
              variant="secondary"
              className={styles.quantityButton}
              outlined
              onClick={
                isModifyingQuantity ? undefined : handleOpenQuantityWidget
              }
              disabled={checkoutPending}
            >
              {itemQuantity}
              <DropDownIcon />
            </Button>
          )}
          {!omitQuantityModifier && isModifyingQuantity && (
            <CartItemQuantityWidget
              onClose={handleCloseQuantityWidget}
              initialValue={itemQuantity}
              itemId={id}
              onChangeQuantity={handleQuantityChange}
            />
          )}
        </div>

        <div
          className={clsx(styles.itemDetailsContainer, {
            [styles.noModifications]: modifications.length === 0,
          })}
        >
          <BodyText fontSize="1.6rem">
            {hasVariants ? variantName : name}
          </BodyText>
          <div>
            {!!groupedModifications.replaced.length && (
              <>
                {groupedModifications.replaced.map((modification, index) => (
                  <BodyText
                    color="body-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >{`${modification}`}</BodyText>
                ))}
              </>
            )}
            {!!groupedModifications.added.length && (
              <>
                {groupedModifications.added.map((modification, index) => (
                  <BodyText
                    color="body-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >{`+ ${modification}`}</BodyText>
                ))}
              </>
            )}
            {!!groupedModifications.removed.length && (
              <>
                {groupedModifications.removed.map((modification, index) => (
                  <BodyText
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color="body-2"
                  >{`- ${modification}`}</BodyText>
                ))}
              </>
            )}
          </div>
        </div>
        <div className={styles.itemCost}>
          {hasDiscount && (
            <BodyText className={styles.discountPrice}>
              {getFormattedCurrency(
                totalPriceInclTax.amount + discountAmountInclTax.amount,
                totalPriceInclTax.currency,
              )}
            </BodyText>
          )}
          <BodyText fontSize="1.6rem">
            {getFormattedCurrency(
              totalPriceInclTax.amount,
              totalPriceInclTax.currency,
            )}
          </BodyText>
        </div>
      </InteractiveDiv>
      {showSeparator && <hr className={styles.separator} />}
    </>
  )
}
