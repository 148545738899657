type LocalStorageKeyType = 'cartMeta' | 'paymentCorrelationId'

class LocalStorageUtils {
  private memory: Record<string, any>

  constructor() {
    this.memory = {}
  }

  /**
   * Sets the value of the specified key in local storage.
   * If an error occurs while setting the value, it is stored in memory instead.
   * @param key - The key to set the value for.
   * @param value - The value to set for the key.
   */
  setItem<T>(key: LocalStorageKeyType, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      this.memory[key] = value
    }
  }

  /**
   * Retrieves the value associated with the given key from local storage.
   * If an error occurs while accessing local storage, the value is retrieved from memory instead.
   * @param key - The key to retrieve the value for.
   * @returns The value associated with the given key, or null if the key is not found.
   */
  getItem<T>(key: LocalStorageKeyType): T | null {
    try {
      const item = localStorage.getItem(key)
      if (item === null) {
        return null
      }
      return JSON.parse(item) as T
    } catch (error) {
      return this.memory[key] ?? null
    }
  }

  /**
   * Removes the item with the specified key from local storage.
   * If an error occurs while removing the item from local storage, the item is removed from memory instead.
   * @param key - The key of the item to remove.
   */
  removeItem(key: LocalStorageKeyType): void {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      delete this.memory[key]
    }
  }
}

export default new LocalStorageUtils()
