import { ErrorCode } from '@ancon/wildcat-types'
import invert from 'lodash/invert'
import getTranslation from 'next-translate/getT'

import { AppGenericErrorType, InformativeError } from '../types'

const ErrorsMap = invert(ErrorCode)

function getResponseErrorId(err: AppGenericErrorType) {
  // @ts-ignore
  const { id } = err?.response?.data?.error ?? {} // Core API Error
  // @ts-ignore
  const { errorCode } = err?.response?.data ?? {} // Core API Error
  // @ts-ignore
  const { code } = err ?? {} // Custom client side CodedError

  return id ?? errorCode ?? code
}
export async function getTranslatedError(
  error: AppGenericErrorType,
  fallback?: InformativeError,
  locale?: string,
) {
  const t = await getTranslation(locale, 'common')
  const id = getResponseErrorId(error)
  const defaultError: InformativeError = {
    title: t('errors.unknown.title'),
    message: t('errors.unknown.message'),
  }

  const errorName = ErrorsMap[id]
  // If no error code exists fallback to default title & message with response error message
  if (errorName) {
    const errorTranslateKey =
      errorName.charAt(0).toLowerCase() + errorName.substring(1)

    return {
      title: t(`errors.${errorTranslateKey}.title`),
      message: t(`errors.${errorTranslateKey}.message`),
    }
  }

  return {
    title: fallback?.title || defaultError.title,
    message: fallback?.message || defaultError.message,
  }
}
