import React from 'react'
import {
  CheckoutDetails,
  CheckoutSummary,
  OrderFormat,
} from '@ancon/wildcat-types'
import getCheckoutGrandTotalAmount from '@ancon/wildcat-utils/checkout/getCheckoutGrandTotalAmount'
import getFormattedCurrency from '@ancon/wildcat-utils/currency/getFormattedCurrency'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import BodyText from '../../../app/components/BodyText'

import styles from './CartTotal.module.scss'

type CartTotalProps = {
  separateTotal?: boolean
  checkout: CheckoutSummary | CheckoutDetails
}

export default function CartTotal({
  separateTotal = false,
  checkout,
}: CartTotalProps) {
  const { t } = useTranslation('common')

  const {
    subtotalInclTax,
    minimumOrderDifferenceAmount,
    feeAmountInclTax,
    discount,
    subTotalDiscountInclTax,
    orderFormat,
  } = checkout

  const isDeliveryOrder = orderFormat === OrderFormat.Delivery
  const checkoutGrandTotal = getCheckoutGrandTotalAmount(
    checkout as CheckoutDetails,
  )

  return (
    <div className={styles.container}>
      {discount && (
        <div className={styles.row}>
          <BodyText color="body-1">
            {t('components.order.discount', { name: discount.name })}
          </BodyText>
          <BodyText color="body-1">
            {`- ${getFormattedCurrency(
              subTotalDiscountInclTax.amount,
              subTotalDiscountInclTax.currency,
            )}`}
          </BodyText>
        </div>
      )}
      <div className={styles.row}>
        <BodyText color="body-1">{t('components.order.subTotal')}</BodyText>
        <BodyText color="body-1">
          {getFormattedCurrency(
            subtotalInclTax.amount,
            subtotalInclTax.currency,
          )}
        </BodyText>
      </div>
      {minimumOrderDifferenceAmount?.amount > 0 && (
        <div className={styles.row}>
          <BodyText color="body-1">{t('components.order.difference')}</BodyText>
          <BodyText color="body-1">
            {getFormattedCurrency(
              minimumOrderDifferenceAmount.amount,
              minimumOrderDifferenceAmount.currency,
            )}
          </BodyText>
        </div>
      )}
      {isDeliveryOrder && (
        <div className={styles.row}>
          <BodyText color="body-1">{t('components.order.delivery')}</BodyText>
          <BodyText color="body-1">
            {getFormattedCurrency(
              feeAmountInclTax.amount,
              feeAmountInclTax.currency,
            )}
          </BodyText>
        </div>
      )}
      <div
        className={clsx(styles.row, {
          [styles.separatedTotalRow]: separateTotal,
        })}
      >
        <div>
          <BodyText fontSize="1.6rem" className={styles.bold}>
            {t('components.order.total')}
          </BodyText>
          <BodyText color="body-1" className={styles.bold}>
            {t('components.order.vatIncluded')}
          </BodyText>
        </div>
        <BodyText
          fontSize={separateTotal ? '2.4rem' : '1.6rem'}
          className={styles.bold}
        >
          {getFormattedCurrency(
            checkoutGrandTotal.amount,
            checkoutGrandTotal.currency,
          )}
        </BodyText>
      </div>
    </div>
  )
}
