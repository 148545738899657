import { createSelector } from '@reduxjs/toolkit'
import getFullName from '@ancon/wildcat-utils/user/getFullName'

import type { RootState } from '../../../store/types'
import { ClientContextState } from '../types'
import isValidPhoneNumber from '../../app/utils/isValidPhoneNumber'

function clientContextStateSelector<K extends keyof ClientContextState>(
  state: RootState,
  key: K,
) {
  return state.clientContext[key]
}

// Client context selectors
export const clientContextSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')

export const clientContextFetchPendingSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContextFetchPending')

export const clientContextCustomerIdSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.customerUserId

export const clientContextCustomerPhoneNumberSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.telephoneNumber

export const clientContextCustomerFirstNameSelector = (state: RootState) =>
  clientContextSelector(state)?.customer?.firstName

// Customer details selectors
export const clientContextCustomerSelector = (state: RootState) =>
  clientContextStateSelector(state, 'customer')

export const clientContextCompanySelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')?.company

export const clientContextAuthenticationUserSelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')?.authenticationUser

export const clientContextCompanyDefaultInvoiceReferenceNoteSelector = (
  state: RootState,
) => clientContextCompanySelector(state)?.defaultInvoiceReferenceNote

export const clientContextCompanyIdSelector = (state: RootState) =>
  clientContextCompanySelector(state)?.companyId

export const clientContextCompanyNameSelector = (state: RootState) =>
  clientContextCompanySelector(state)?.name

export const clientContextIsCompanySelector = (state: RootState) =>
  clientContextStateSelector(state, 'clientContext')?.isCompany

export const clientContextIsCompanyUserSelector = createSelector(
  [clientContextIsCompanySelector, clientContextCompanyIdSelector],
  (isCompany, companyId) => isCompany && companyId,
)

export const clientContextCustomerEmailSelector = (state: RootState) =>
  clientContextCustomerSelector(state)?.email

export const clientContextCustomerAddressesSelector = (state: RootState) =>
  clientContextCustomerSelector(state)?.addresses

export const clientContextCustomerFullNameSelector = createSelector(
  [clientContextCustomerSelector, clientContextSelector],
  (customer, clientContext) => getFullName(customer || clientContext?.customer),
)

export const clientContextProfileNameSelector = createSelector(
  [
    clientContextCompanyIdSelector,
    clientContextCompanyNameSelector,
    clientContextCustomerFullNameSelector,
  ],
  (companyId, companyName, customerFullName) =>
    companyId ? companyName : customerFullName,
)

export const clientContextCustomerDetailsSelector = createSelector(
  clientContextSelector,
  clientContext => clientContext?.customer,
)

export const clientContextAuthenticationUserSignInProviderSelector =
  createSelector(
    clientContextAuthenticationUserSelector,
    authenticationUser => authenticationUser?.signInProvider,
  )

export const clientContextIsAnonymousCustomerSelector = createSelector(
  clientContextAuthenticationUserSelector,
  authenticationUser =>
    !!authenticationUser?.signInProvider &&
    authenticationUser?.signInProvider === 'anonymous',
)

export const isUserWithIncompleteCustomerDetailsSelector = createSelector(
  clientContextSelector,
  clientContext =>
    !!clientContext?.authenticationUser?.signInProvider &&
    clientContext?.authenticationUser?.signInProvider !== 'anonymous' &&
    (!clientContext?.customer?.email ||
      !isValidPhoneNumber(clientContext?.customer?.telephoneNumber || '')),
)
