import { PayloadAction } from '@reduxjs/toolkit'
import { OutletListItem } from '@ancon/wildcat-types'

import {
  OutletsReducerState,
  OutletsFilters,
  OutletSearchModalType,
} from '../types'

export const outletsReducers = {
  outletsSetOutletsFilters: (
    state: OutletsReducerState,
    action: PayloadAction<Partial<OutletsFilters>>,
  ) => {
    state.outletsFilters = { ...state.outletsFilters, ...action.payload }
  },
  outletsSetVisibleOutletSearchModal: (
    state: OutletsReducerState,
    action: PayloadAction<OutletSearchModalType>,
  ) => {
    state.visibleOutletSearchModal = action.payload
  },
  outletsSetSelectedOutletListItem: (
    state: OutletsReducerState,
    action: PayloadAction<OutletListItem | null>,
  ) => {
    state.selectedOutletListItem = action.payload
  },
  outletsSetVisibleOrderFormatSelectionModal: (
    state: OutletsReducerState,
    action: PayloadAction<boolean>,
  ) => {
    state.visibleOrderFormatSelectionModal = action.payload
  },
}
