import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { AccountReducerState } from '../types'

import accountReducers from './accountReducers'
import {
  createCustomerAddress,
  deleteCustomerAddress,
  fetchCustomerAddressesList,
  updateCustomerAddress,
} from './accountThunks'

const accountInitialState: AccountReducerState = {
  fetchAddressListPending: false,
  addressCreatePending: false,
  addressDeletePending: false,
  addressUpdatePending: false,

  configureAddressModalState: {
    isVisible: false,
  },
}

const accountSlice = createSlice({
  name: 'account',
  initialState: accountInitialState,
  reducers: accountReducers,
  extraReducers(builder) {
    builder.addCase(fetchCustomerAddressesList.pending, state => {
      state.fetchAddressListPending = true
    })

    builder.addCase(createCustomerAddress.pending, state => {
      state.addressCreatePending = true
    })

    builder.addCase(updateCustomerAddress.pending, state => {
      state.addressUpdatePending = true
    })

    builder.addCase(deleteCustomerAddress.pending, state => {
      state.addressDeletePending = true
    })

    builder.addMatcher(
      isAnyOf(
        fetchCustomerAddressesList.fulfilled,
        fetchCustomerAddressesList.rejected,
      ),
      state => {
        state.fetchAddressListPending = false
      },
    )

    builder.addMatcher(
      isAnyOf(createCustomerAddress.fulfilled, createCustomerAddress.rejected),
      state => {
        state.addressCreatePending = false
      },
    )

    builder.addMatcher(
      isAnyOf(updateCustomerAddress.fulfilled, updateCustomerAddress.rejected),
      state => {
        state.addressUpdatePending = false
      },
    )

    builder.addMatcher(
      isAnyOf(deleteCustomerAddress.fulfilled, deleteCustomerAddress.rejected),
      state => {
        state.addressDeletePending = false
      },
    )
  },
})

export const { setAccountConfigureAddressModalState } = accountSlice.actions

export default accountSlice.reducer
