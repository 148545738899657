import { PayloadAction } from '@reduxjs/toolkit'

import { AuthModalType, AuthReducerState } from '../types'

const authReducers = {
  /**
   * Payload is whether to show the sign in or sign up modal.
   * default is sign in.
   */
  showAuthModal(
    state: AuthReducerState,
    action: PayloadAction<AuthModalType | undefined>,
  ) {
    state.showAuthModal = true
    state.authModalType = action.payload
  },
  hideAuthModal(state: AuthReducerState) {
    state.showAuthModal = false
    state.authModalType = undefined
  },
  setAuthClientSDKInitialized(state: AuthReducerState) {
    state.clientSDKLoaded = true
  },
  setAuthCompanySignUpModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showCompanySignUpModal = action.payload
  },
  setAuthAccountEditModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showEditAccountModal = action.payload
  },
  setAuthGuestCustomerOnboardingModalVisible(
    state: AuthReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.showGuestCustomerOnboardingModal = action.payload
  },
}

export default authReducers
