import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../../store/types'
import { OutletsReducerState } from '../types'
import {
  outletDetailsSelector,
  outletIsOutletDetailsPendingSelector,
} from '../../outlet/store/outletSelector'

import { outletListEntityAdapter } from './outletsEntityAdapters'

function outletsStateSelector<K extends keyof OutletsReducerState>(
  state: RootState,
  key: K,
) {
  return state.outlets[key]
}

const listAdapterSelectors = outletListEntityAdapter.getSelectors(
  (state: RootState) => state.outlets.list,
)

export const { selectAll: nearbyOutletsListSelector } = listAdapterSelectors

export const outletsHasMoreSelector = (state: RootState) =>
  outletsStateSelector(state, 'hasMore')

export const outletsOffsetSelector = (state: RootState) =>
  outletsStateSelector(state, 'offset')

export const outletsFiltersSelector = (state: RootState) =>
  outletsStateSelector(state, 'outletsFilters')

export const outletListFetchPendingSelector = (state: RootState) =>
  outletsStateSelector(state, 'outletListFetchPending')

export const outletsFiltersLocationSearchTermSelector = (state: RootState) =>
  outletsFiltersSelector(state).location?.visibleSearchTerm

export const outletsVisibleOutletSearchModalSelector = (state: RootState) =>
  outletsStateSelector(state, 'visibleOutletSearchModal')

export const outletsSelectedOutletListItemSelector = (state: RootState) =>
  outletsStateSelector(state, 'selectedOutletListItem')

export const outletsSelectedOutletListIdSelector = (state: RootState) =>
  outletsSelectedOutletListItemSelector(state)?.id

export const outletsVisibleOrderFormatSelectionModalSelector = (
  state: RootState,
) => outletsStateSelector(state, 'visibleOrderFormatSelectionModal')

// Combine selector
export const outletsIsNewOrderModalVisibleSelector = createSelector(
  [
    outletsSelectedOutletListItemSelector,
    outletDetailsSelector,
    outletIsOutletDetailsPendingSelector,
  ],
  (
    outletsSelectedOutletListItem,
    outletDetails,
    outletIsOutletDetailsPending,
  ) =>
    outletIsOutletDetailsPending ||
    !!(outletsSelectedOutletListItem && outletDetails),
)
