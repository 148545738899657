import { SignInProvider } from '@ancon/wildcat-types'

export type AuthReducerState = {
  clientSDKLoaded: boolean

  loggedIn: boolean | null

  signInPending: boolean
  signInProvider: SignInProvider | null

  signUpPending: boolean

  socialSignUpPending: boolean

  showAuthModal: boolean
  isSignUpModal: boolean
  showCompanySignUpModal: boolean
  showEditAccountModal: boolean
  showGuestCustomerOnboardingModal: boolean

  authModalType?: AuthModalType

  signOutPending: boolean
}

export enum AuthModalType {
  SignIn,
  SignUp,
  ForgotPassword,
}

export enum SignUpStep {
  Credentials,
  UserDetails,
}

export type LogInFormState = {
  email: string
  password: string
}

export type ForgotPasswordFormState = {
  email: string
}

export type SignUpFormState = {
  agreedToTerms: boolean
  currentStep: SignUpStep
  email: string
  firstName: string
  lastName: string
  password: string
  phoneNumber: string
}

export type UserRegistrationData = {
  email: string
  password: string
  firstName: string
  lastName: string
  telephoneNumber: string
  languageCode: string | null
}

export type SignInWithActionPayload = {
  signInProvider: SignInProvider
  credential?: {
    email: string
    password: string
  }
}

export type UserUpdateData = {
  firstName: string
  lastName: string
  telephoneNumber: string
  languageCode: string | null
}

export type CompanySignUpFormState = {
  companyName: string
  businessNumber: string
  email: string
  password: string
  street: string
  zipCode: string
  city: string
  country: string
  firstName: string
  lastName: string
  telephoneNumber: string
}
