import React from 'react'
import useTranslation from 'next-translate/useTranslation'

import Button from '../Button'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { appErrorModalConfigSelector } from '../../store/appSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsErrorModalVisible } from '../../store/appSlice'
import BodyText from '../BodyText'
import { ErrorModalType } from '../../types'
import { setProductDetailsModalVisible } from '../../../product/store/productSlice'
import { checkoutAddPaymentAttempt } from '../../../checkout/store/checkoutSlice'
import storedPaymentCorrelationId from '../../../checkout/utils/storedPaymentCorrelationId'

import styles from './ErrorModalFooter.module.scss'

export default function ErrorModalFooter() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')

  const errorModalConfig = useAppSelector(appErrorModalConfigSelector)

  function handleRequestClose() {
    switch (errorModalConfig?.errorModalType) {
      case ErrorModalType.ProductOutOfStock:
        dispatch(setProductDetailsModalVisible(false))
        break
      case ErrorModalType.CheckoutPaymentError:
        storedPaymentCorrelationId.refresh()
        dispatch(checkoutAddPaymentAttempt())
        break
      default:
    }

    dispatch(appSetIsErrorModalVisible(false))
  }

  switch (errorModalConfig?.errorModalType) {
    case ErrorModalType.CheckoutItemPriceChanged:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('okay')}</BodyText>
        </Button>
      )
    case ErrorModalType.ProductOutOfStock:
    case ErrorModalType.OutletNotAvailableForOrders:
    case ErrorModalType.CheckoutFetchError:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('close')}</BodyText>
        </Button>
      )
    default:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('retry')}</BodyText>
        </Button>
      )
  }
}
