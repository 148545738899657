import clsx from 'clsx'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import UserProfileIcon from '@ancon/wildcat-ui/shared/icons/circle-user-outline.svg'
import ShoppingBagOutlineIcon from '@ancon/wildcat-ui/shared/icons/shopping-bag-outline.svg'
import EditAccountIcon from '@ancon/wildcat-ui/shared/icons/pen-line-outline.svg'
import GlobeIcon from '@ancon/wildcat-ui/shared/icons/globe-outline.svg'
import CompanyIcon from '@ancon/wildcat-ui/shared/icons/company-outline.svg'

import Button from '../../../../app/components/Button'
import HeadingText from '../../../../app/components/HeadingText'
import BodyText from '../../../../app/components/BodyText'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { appIsMobileDrawerVisibleSelector } from '../../../../app/store/appSelectors'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import {
  appSetIsMobileDrawerVisible,
  appSetIsSwitchLanguageModalVisible,
} from '../../../../app/store/appSlice'
import {
  clientContextCompanyNameSelector,
  clientContextCustomerFullNameSelector,
  clientContextIsAnonymousCustomerSelector,
  clientContextProfileNameSelector,
} from '../../../../clientContext/store/clientContextSelectors'
import {
  authCustomerIsLoggedInSelector,
  authSignOutPendingSelector,
} from '../../../../auth/store/authSelectors'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import {
  setAuthCompanySignUpModalVisible,
  setAuthGuestCustomerOnboardingModalVisible,
  showAuthModal,
} from '../../../../auth/store/authSlice'
import { signOut } from '../../../../auth/store/authThunks'
import InteractiveDiv from '../../InteractiveDiv'
import { AppRoutes } from '../../../../app/constants'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { fetchOutletDetails } from '../../../../outlet/store/outletThunks'
import { outletSelectedOutletIdSelector } from '../../../../outlet/store/outletSelector'
import useAppStore from '../../../../../store/hooks/useAppStore'
import { AuthModalType } from '../../../../auth/types'

import styles from './MobileSideDrawer.module.scss'

export default function MobileSideDrawer() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const store = useAppStore()

  const router = useRouter()

  const isDrawerVisible = useAppSelector(appIsMobileDrawerVisibleSelector)

  const isSignOutPending = useAppSelector(authSignOutPendingSelector)

  const customerFullName = useAppSelector(clientContextCustomerFullNameSelector)

  const isAnonymousCustomer = useAppSelector(
    clientContextIsAnonymousCustomerSelector,
  )

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)

  const companyName = useAppSelector(clientContextCompanyNameSelector)

  const profileName = useAppSelector(clientContextProfileNameSelector)

  const isOutletPage = useRouteMatch([AppRoutes.Outlet])

  const isGiftCardPage = useRouteMatch([AppRoutes.GiftCard])

  const isWiteLabeledApp = isAppWhiteLabeled()

  function handleCloseDrawer() {
    dispatch(appSetIsMobileDrawerVisible(false))
  }

  function handleSignInClick() {
    handleCloseDrawer()
    dispatch(showAuthModal(AuthModalType.SignIn))
  }

  function handleSignUpClick() {
    handleCloseDrawer()
    dispatch(showAuthModal(AuthModalType.SignUp))
  }

  function handleSignUpAsCompanyClick() {
    const outletId = outletSelectedOutletIdSelector(store.getState())!
    handleCloseDrawer()
    dispatch(setAuthCompanySignUpModalVisible(true))
    dispatch(fetchOutletDetails({ outletId }))
  }

  function handleNavigateToMyOrdersClick() {
    handleCloseDrawer()
    router.push(AppRoutes.Orders)
  }

  function handleNavigateToManageAccountClick() {
    handleCloseDrawer()
    router.push(AppRoutes.Account)
  }

  function handleCreateProfileClick() {
    handleCloseDrawer()
    dispatch(setAuthGuestCustomerOnboardingModalVisible(true))
  }

  function handleSignOutClick() {
    handleCloseDrawer()
    dispatch(signOut())
  }

  function handleSwitchLanguageClick() {
    dispatch(appSetIsSwitchLanguageModalVisible(true))
  }

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.classList.add(styles.isOpen)
    } else {
      document.body.classList.remove(styles.isOpen)
    }
  }, [isDrawerVisible])

  return (
    <div
      className={clsx(styles.container, {
        [styles.isDrawerVisible]: isDrawerVisible,
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.userInfoContainer}>
          {companyName ? <CompanyIcon /> : <UserProfileIcon />}
          <div className={styles.userMetaContainer}>
            <HeadingText as="h2">
              {isLoggedIn ? profileName : t('components.navBar.auth.hiThere')}
            </HeadingText>
            {isLoggedIn && (
              <BodyText color="body-1">
                {(!!companyName && customerFullName) ||
                  (isAnonymousCustomer && t('components.navBar.auth.guest'))}
              </BodyText>
            )}
          </div>
        </div>
        <Button variant="secondary" onClick={handleCloseDrawer}>
          <CloseIcon />
        </Button>
      </div>
      <div className={styles.contentContainer}>
        {!isGiftCardPage && !isLoggedIn && (
          <div className={styles.authActionsContainer}>
            <Button onClick={handleSignInClick}>
              {t('components.navBar.auth.signIn')}
            </Button>
            <Button variant="secondary" onClick={handleSignUpClick}>
              {t('components.navBar.auth.signUp')}
            </Button>
            {(isOutletPage || isWiteLabeledApp) && (
              <Button
                variant="secondary"
                className={styles.companySignUp}
                onClick={handleSignUpAsCompanyClick}
              >
                {t('companySignUpModal.title')}
              </Button>
            )}
          </div>
        )}

        {!isGiftCardPage && isLoggedIn && (
          <div className={styles.navigationList}>
            <InteractiveDiv
              className={styles.navItem}
              onClick={handleNavigateToMyOrdersClick}
            >
              <ShoppingBagOutlineIcon />
              <BodyText> {t('components.navBar.profile.myOrders')}</BodyText>
            </InteractiveDiv>
            <InteractiveDiv
              className={clsx(styles.navItem, {
                [styles.disabled]: isAnonymousCustomer,
              })}
              onClick={handleNavigateToManageAccountClick}
              disabled={isAnonymousCustomer}
            >
              <EditAccountIcon />
              <BodyText>{t('components.navBar.auth.manageAccount')}</BodyText>
            </InteractiveDiv>
          </div>
        )}
        {!isGiftCardPage && isLoggedIn && (
          <div className={styles.accountActionsContainer}>
            {isAnonymousCustomer && (
              <InteractiveDiv
                className={styles.actionItem}
                onClick={handleCreateProfileClick}
              >
                <BodyText>
                  {t(
                    isWiteLabeledApp
                      ? 'components.navBar.auth.createProfile'
                      : 'components.navBar.auth.createAnconProfile',
                  )}
                </BodyText>
              </InteractiveDiv>
            )}
            {!isAnonymousCustomer && (
              <InteractiveDiv
                className={styles.actionItem}
                onClick={handleSignOutClick}
                disabled={isSignOutPending}
              >
                <BodyText onClick={handleSignOutClick}>
                  {t('components.navBar.profile.signOut')}
                </BodyText>
              </InteractiveDiv>
            )}
          </div>
        )}
      </div>

      <div className={styles.footerContainer}>
        <InteractiveDiv
          className={styles.footerItem}
          onClick={handleSwitchLanguageClick}
        >
          <GlobeIcon />
          <BodyText>{t('components.navBar.switchLanguage')}</BodyText>
        </InteractiveDiv>
      </div>
    </div>
  )
}
