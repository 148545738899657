import {
  NearbyOutletsPaginatedResponse,
  OrderFormat,
  OutletSearchListItem,
  PaginatedResponse,
  SystemVisibility,
} from '@ancon/wildcat-types'
import Moment from 'moment'

import api from '../../../api'
import {
  outletsFilterOrderFormats,
  defaultPaginatedResponse,
} from '../constants'
import { GeoPosition } from '../../location/types'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import getTenantIds from '../../app/utils/getTenantIds'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'

import { outletsFiltersSelector } from './outletsSelectors'

export const fetchNearbyOutlets = createAppAsyncThunk<
  NearbyOutletsPaginatedResponse,
  {
    offset: number
    limit: number
    searchTerm?: string
    geoPosition?: GeoPosition
    keepExisting?: boolean
  }
>(
  'outlets/fetchNearbyOutlets',
  async ({ offset, limit, searchTerm, geoPosition }, { getState }) => {
    const tenantIds = getTenantIds()

    const outletsFilters = outletsFiltersSelector(getState())
    const isWhiteLabeled = isAppWhiteLabeled()

    const orderCriteria = ['state:asc']
    const locationFiltered =
      (isWhiteLabeled || !outletsFilters?.searchTerm) &&
      !!(outletsFilters?.location?.latitude || geoPosition?.latitude)

    if (locationFiltered) {
      orderCriteria.push('distance:asc')
    }
    orderCriteria.push('name:asc')

    const response = await api.core.outlets.get.nearby({
      offset,
      limit,
      radius: tenantIds?.length ? undefined : 20000, // 20 KM
      orderFormats: OrderFormat.All,
      systemVisibility: SystemVisibility.OrderWeb,
      search: searchTerm ?? (outletsFilters?.searchTerm || undefined),
      latitude: outletsFilters?.searchTerm
        ? undefined
        : geoPosition?.latitude || outletsFilters?.location?.latitude,
      longitude: outletsFilters?.searchTerm
        ? undefined
        : geoPosition?.longitude || outletsFilters?.location?.longitude,
      order: orderCriteria.join(','),
      ...(tenantIds?.length && {
        filter: {
          tenantId: tenantIds.map(id => `in:${id}`),
        },
      }),
    })

    return {
      items: response?.data?.items ?? [],
      meta: {
        count: response?.data?.meta?.count ?? 0,
        offset: response?.data?.meta?.offset ?? 0,
      },
    }
  },
)
export const searchOutlets = createAppAsyncThunk<
  PaginatedResponse<OutletSearchListItem>,
  {
    searchTerm?: string
    limit?: number
    orderFormat?: OrderFormat
  }
>(
  'outlets/searchOutlets',
  async ({
    searchTerm,
    limit = 4,
    orderFormat = outletsFilterOrderFormats,
  }) => {
    const tenantIds = getTenantIds()
    const dateTimeUtc = Moment().utc()

    const response = await api.core.outlets.get.search({
      search: searchTerm,
      systemVisibility: SystemVisibility.OrderWeb,
      orderFormats: orderFormat,
      date: dateTimeUtc.toISOString(),
      time: dateTimeUtc.format('HH:mm:ss'),
      offset: 0,
      limit,
      order: 'distance:asc,name:asc',
      ...(tenantIds?.length && {
        filter: {
          tenantId: tenantIds.map((id: string) => `in:${id}`),
        },
      }),
    })

    return response?.data || defaultPaginatedResponse
  },
)
