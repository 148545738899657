import { FormProvider, useForm } from 'react-hook-form'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'
import useTranslation from 'next-translate/useTranslation'
import Alert from '@ancon/wildcat-ui/web/orderweb/Alert'
import { useState } from 'react'

import { LogInFormState } from '../../../auth/types'
import LoginFormInputs from '../../../auth/forms/LoginFormInputs'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { linkCredentialsToGuestUser } from '../../../auth/store/authThunks'
import { getTranslatedError } from '../../../app/utils/getTranslatedError'
import { appLanguageSelector } from '../../../app/store/appSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'

import styles from './GuestCustomerOnboardingForm.module.scss'

type OrderStatusCreateAccountFormProps = {
  onClickClose: () => void
}

export default function OrderStatusCreateAccountForm({
  onClickClose,
}: OrderStatusCreateAccountFormProps) {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const [accountLinkError, setAccountLinkError] = useState<string | null>(null)
  const form = useForm<LogInFormState>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form

  async function handleOnClickCreateAccount(values: LogInFormState) {
    try {
      await dispatch(linkCredentialsToGuestUser(values)).unwrap()
      onClickClose()
    } catch (err) {
      const locale = appLanguageSelector(store.getState())
      const translatedError = await getTranslatedError(err, undefined, locale)

      setAccountLinkError(translatedError?.message || translatedError?.title)
    }
  }

  function clearAccountLinkError() {
    setAccountLinkError(null)
  }

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      <Alert
        className={styles.alert}
        isOpen={!!accountLinkError}
        toggle={clearAccountLinkError}
        type="danger"
      >
        {accountLinkError}
      </Alert>
      <LoginFormInputs />
      <div className={styles.modalFooter}>
        <Button variant="secondary" onClick={onClickClose}>
          {t('components.createAccountModal.cancel')}
        </Button>
        <Button
          onClick={handleSubmit(handleOnClickCreateAccount)}
          loading={isSubmitting}
        >
          {t('components.createAccountModal.signUp')}
        </Button>
      </div>
    </FormProvider>
  )
}
