import {
  ClientContextResponse,
  CustomerUpdatePayload,
  CustomerUserDetails,
} from '@ancon/wildcat-types'

import api from '../../../api'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'

import { clientContextCustomerIdSelector } from './clientContextSelectors'

export const fetchCustomerDetails = createAppAsyncThunk<
  CustomerUserDetails | null,
  string
>('clientContext/fetchCustomerDetails', async customerId => {
  try {
    const response = await api.user.customers.get.details({
      customerId,
    })
    return response?.data.customer || null
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    throw err
  }
})

export const fetchClientContext = createAppAsyncThunk<
  ClientContextResponse | null,
  void
>('clientContext/fetchClientContext', async (_, { dispatch }) => {
  const response = await api.core.clientContext.get.details()

  const clientContext = response?.data

  const customer = clientContext?.customer

  if (customer?.email) {
    await dispatch(fetchCustomerDetails(customer.customerUserId))
  }

  return clientContext || null
})

export const updateCustomerDetails = createAppAsyncThunk<
  void,
  CustomerUpdatePayload
>('clientContext/updateCustomerDetails', async (customerData, { getState }) => {
  try {
    const customerId = clientContextCustomerIdSelector(getState())

    if (customerId) {
      await api.user.customers.put.byId(customerData, { customerId })
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    throw err
  }
})

export const activateCustomer = createAppAsyncThunk<void, { email: string }>(
  'auth/activateCustomer',
  async ({ email }, { getState }) => {
    try {
      const customerId = clientContextCustomerIdSelector(getState())

      if (customerId) {
        await api.user.customers.put.activate({ email }, { customerId })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      throw err
    }
  },
)
