'use client'

import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { outletSetOrderConfigureFilterModal } from '../../../../outlet/store/outletSlice'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../../../app/constants'
import BodyText from '../../../../app/components/BodyText'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { outletFiltersOrderFormatSelector } from '../../../../outlet/store/outletSelector'
import OrderTypeIcon from '../../../../app/components/OrderTypeIcon'
import { orderFormatTextMap } from '../../../../outlets/constants'
import { OrderConfigureFilterMode } from '../../../../outlet/types'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'

import styles from './NavbarOrderFormatFilter.module.scss'

type NavbarOrderFilterProps = {
  isInNavBar?: boolean
}

const OutletFilterEnabledPaths: string[] = [`${AppRoutes.Outlet}/`]

export default function NavbarOrderFormatFilter({
  isInNavBar = true,
}: NavbarOrderFilterProps) {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isOutletFiltersEnabled = useRouteMatch(OutletFilterEnabledPaths)

  const orderFormat = useAppSelector(outletFiltersOrderFormatSelector)

  const isWhiteLabeled = isAppWhiteLabeled()

  function handleOnClick() {
    dispatch(
      outletSetOrderConfigureFilterModal(OrderConfigureFilterMode.OrderType),
    )
  }

  if (!isOutletFiltersEnabled || !orderFormat) {
    return null
  }

  return (
    <Button
      variant="secondary"
      outlined={!isInNavBar}
      className={clsx(styles.container, isInNavBar && styles.navBar, {
        [styles.whiteLabeled]: isWhiteLabeled,
      })}
      onClick={handleOnClick}
    >
      <span className={styles.orderFormatData}>
        <OrderTypeIcon orderFormat={orderFormat!} />
        <BodyText as="span">
          {t(orderFormatTextMap[orderFormat as never])}
        </BodyText>
      </span>
    </Button>
  )
}
