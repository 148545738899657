import React, { forwardRef, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useDebouncedCallback } from 'use-debounce'
import { useRouter } from 'next/router'

import QuantityModifier from '../../../app/components/QuantityModifier'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  changeCheckoutItemQuantity,
  deleteCheckoutItem,
} from '../../store/checkoutThunks'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'
import { checkoutCurrentCheckoutPendingSelector } from '../../store/checkoutSelectors'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { AppRoutes } from '../../../app/constants'
import useRouteMatch from '../../../app/hooks/useRouteMatch'

import styles from './CartItemQuantityWidget.module.scss'

type CartItemQuantityWidgetProps = {
  itemId: string
  initialValue: number
  onChangeQuantity: (quantity: number) => void
  onClose: () => void
}

function CartItemQuantityWidget({
  itemId,
  initialValue,
  onChangeQuantity,
  onClose,
}: CartItemQuantityWidgetProps) {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const quantityWidgetRef = useRef<HTMLDivElement>(null)
  const isCheckoutPage = useRouteMatch([AppRoutes.Checkout])

  const isCheckoutPending = useAppSelector(
    checkoutCurrentCheckoutPendingSelector,
  )

  const debouncedOnChangeQuantity = useDebouncedCallback(
    (newQuantity: number) => {
      if (newQuantity === 0) {
        dispatch(deleteCheckoutItem({ checkoutItemId: itemId }))
          .unwrap()
          .then(response => {
            if (response?.isCheckoutDeleted && isCheckoutPage) {
              router.replace('/')
            }
          })
      } else {
        dispatch(
          changeCheckoutItemQuantity({
            checkoutItemId: itemId,
            quantity: newQuantity,
          }),
        )
          .unwrap()
          .catch(() => onChangeQuantity(initialValue))
      }
    },
    400,
  )

  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      const clickedOnWidget =
        quantityWidgetRef.current &&
        quantityWidgetRef.current.contains(e.target as Node)

      if (!clickedOnWidget) {
        onClose()
      }
    }

    document.addEventListener('mouseup', handleMouseDown)

    return () => {
      document.removeEventListener('mouseup', handleMouseDown)
    }
  }, [onClose])

  return (
    <InteractiveDiv
      className={clsx(styles.quantityModifier)}
      ref={quantityWidgetRef}
    >
      <QuantityModifier
        onChange={qty => {
          onChangeQuantity(qty)
          debouncedOnChangeQuantity(qty)
        }}
        withRemoveOption
        value={initialValue}
        disabled={isCheckoutPending}
        min={0}
      />
    </InteractiveDiv>
  )
}

export default forwardRef(CartItemQuantityWidget)
