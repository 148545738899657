/* eslint-disable no-param-reassign */
import {
  CheckoutItemStatusUpdatedSignal,
  CheckoutStatusUpdatedSignal,
  TicketStatus,
} from '@ancon/wildcat-types'
import { PayloadAction } from '@reduxjs/toolkit'
import { CheckoutStatus } from '@ancon/wildcat-types/dist/checkout'

import { OrderReducerState } from '../types'

import { activeOrdersEntityAdapter } from './ordersEntityAdapter'

const ordersReducers = {
  ordersClearOrderDetails(state: OrderReducerState) {
    state.orderDetails = null
  },
  ordersUpdateOrderSummaryTicketStatus(
    state: OrderReducerState,
    action: PayloadAction<TicketStatus>,
  ) {
    if (state.orderSummary) {
      state.orderSummary.ticketStatus = action.payload
    }
  },
  ordersHandleCheckoutStatusSignal(
    state: OrderReducerState,
    action: PayloadAction<CheckoutStatusUpdatedSignal>,
  ) {
    const { checkoutId, status } = action.payload
    const checkoutDetails = state.activeOrders.entities[checkoutId]
    if (checkoutDetails && !Number.isNaN(status)) {
      if (status === CheckoutStatus.Done) {
        activeOrdersEntityAdapter.removeOne(state.activeOrders, checkoutId)
      } else {
        activeOrdersEntityAdapter.updateOne(state.activeOrders, {
          id: checkoutId,
          changes: {
            status,
          },
        })
      }
    }
  },
  ordersHandleCheckoutItemStatusSignal(
    state: OrderReducerState,
    action: PayloadAction<CheckoutItemStatusUpdatedSignal>,
  ) {
    const { checkoutId, checkoutItemId, ticketItemId, ticketItemStatus } =
      action.payload
    const checkoutDetails = state.activeOrders.entities[checkoutId]

    if (checkoutDetails) {
      activeOrdersEntityAdapter.updateOne(state.activeOrders, {
        id: checkoutId,
        changes: {
          ...checkoutDetails,
          items: checkoutDetails.items.map(item => {
            if (item.id === checkoutItemId) {
              item.ticketItem = {
                ticketItemId,
                status: ticketItemStatus,
              }
            }
            return item
          }),
        },
      })
    }
  },
}

export default ordersReducers
