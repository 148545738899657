import { OrderFormat } from '@ancon/wildcat-types'

export const outletsFilterOrderFormats =
  OrderFormat.EatIn |
  OrderFormat.TakeAway |
  OrderFormat.Delivery |
  OrderFormat.TableOrder

export const defaultPaginatedResponse = {
  items: [],
  meta: {
    count: 0,
    offset: 0,
  },
}

export const orderFormatTextMap = {
  [OrderFormat.EatIn]: 'orderFormat.eatIn',
  [OrderFormat.TakeAway]: 'orderFormat.takeAway',
  [OrderFormat.Delivery]: 'orderFormat.delivery',
  [OrderFormat.TableOrder]: 'orderFormat.tableOrder',
}

export const AllOrderFormatsArray = [
  OrderFormat.EatIn,
  OrderFormat.TakeAway,
  OrderFormat.Delivery,
  OrderFormat.TableOrder,
]
