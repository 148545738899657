import {
  MenuContentType,
  MenuGroup,
  MenuTreeHierarchyProduct,
} from '@ancon/wildcat-types'

export default function extractProductsFromMenuTree(menuGroups: MenuGroup[]) {
  const products = menuGroups.reduce<MenuTreeHierarchyProduct[]>(
    (acc, group) => {
      if (group.items?.length) {
        if (group.contentType === MenuContentType.Product) {
          acc.push(...group.items)
        } else if (group.contentType === MenuContentType.SubMenu) {
          group.items.forEach(subGroup => {
            acc.push(...subGroup.items)
          })
        }
      }

      return acc
    },
    [],
  )

  return products
}
