/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit'
import {
  CheckoutItemStatusUpdatedEvent,
  CheckoutPreptimeUpdatedEvent,
  CheckoutStatusUpdatedEvent,
  CheckoutStatus,
} from '@ancon/wildcat-types'

import { CheckoutFilters, CheckoutReducerState } from '../types'
import getCheckoutFilters from '../utils/getCheckoutFilters'

const checkoutReducers = {
  checkoutSetCheckoutFilters(
    state: CheckoutReducerState,
    action: PayloadAction<Partial<CheckoutFilters>>,
  ) {
    const { payload } = action
    state.checkoutFilters = getCheckoutFilters(state.checkoutFilters, payload)
  },
  setSelectedCheckoutItemId(
    state: CheckoutReducerState,
    action: PayloadAction<string | null>,
  ) {
    state.currentCheckoutSelectedCheckoutItemId = action.payload
  },
  checkoutSetIsVisibleMinimumOrderModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isMinimumOrderModalVisible = action.payload
  },
  clearCurrentCheckout(state: CheckoutReducerState) {
    state.currentCheckoutId = null
    state.currentCheckoutDetails = null
  },
  checkoutSetIsVisibleDiscountModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isDiscountModalVisible = action.payload
  },
  checkoutSetIsVisibleCustomerInfoModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isCustomerInfoModalVisible = action.payload
  },
  checkoutSetIsVisibleDeleteOrderModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isVisibleDeleteOrderModal = action.payload
  },
  checkoutClearCheckoutSummary(state: CheckoutReducerState) {
    state.checkoutSummary = null
    state.checkoutSummaryOutletListItem = null
    state.checkoutSummaryFetchPending = false
    state.checkoutStatusLastUpdatedAt = 0
  },
  checkoutEmitStatusUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutStatusUpdatedEvent>,
  ) {
    function handleCheckoutStatusEvent(
      key: 'checkoutSummary' | 'currentCheckoutDetails',
    ) {
      if (state[key] && action.payload.checkoutId === state[key]!.id) {
        let timestampDateValue = new Date(action.payload.timestamp).valueOf()

        if (Number.isNaN(timestampDateValue)) {
          timestampDateValue = 0
        }

        if (
          !Number.isNaN(action.payload.status) &&
          timestampDateValue >= state.checkoutStatusLastUpdatedAt
        ) {
          state[key]!.status = action.payload.status
          state.checkoutStatusLastUpdatedAt = timestampDateValue

          if (action.payload.status === CheckoutStatus.InProgress) {
            // Update service time - otherwise there can be difference with POS and AOW
            state[key]!.serviceTime.time = action.payload.timestamp
          }
        }

        if (action.payload.cancelInfo) {
          state[key]!.cancelInfo = action.payload.cancelInfo
        }

        if (action.payload.preparationTime) {
          state[key]!.tickets[0] = {
            ...state[key]!.tickets[0],
            preparationTime: action.payload.preparationTime,
          }
        }

        if (action.payload.ticketId) {
          state[key]!.tickets[0] = {
            ...state[key]!.tickets[0],
            id: action.payload.ticketId,
          }
        }

        /** When checkout status changing from Authorized -> Captured, orderId should be set */
        if (action.payload.orderId && !state[key]!.orderId) {
          state[key]!.orderId = action.payload.orderId
        }
      }
    }

    handleCheckoutStatusEvent('checkoutSummary')
    // Also update the current checkout details
    handleCheckoutStatusEvent('currentCheckoutDetails')
  },
  checkoutEmitPreptimeUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutPreptimeUpdatedEvent>,
  ) {
    if (
      state.checkoutSummary &&
      action.payload.checkoutId === state.checkoutSummary.id
    ) {
      if (state.checkoutSummary.tickets[0]) {
        if (action.payload.preparationTime) {
          state.checkoutSummary.tickets[0].preparationTime =
            action.payload.preparationTime
        }
      }
    }
  },
  checkoutItemStatusUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutItemStatusUpdatedEvent>,
  ) {
    if (
      state.checkoutSummary &&
      action.payload.checkoutId === state.checkoutSummary.id
    ) {
      state.checkoutSummary.items.forEach(item => {
        if (item.id === action.payload.checkoutItemId) {
          item.ticketItem = {
            ...item.ticketItem,
            ticketItemId: action.payload.ticketItemId,
            status: action.payload.ticketItemStatus,
          }
        }
      })
    }
  },
  checkoutAddPaymentAttempt(state: CheckoutReducerState) {
    state.checkoutPaymentAttempt += 1
  },
  checkoutUpdateCheckoutSummaryStatus(
    state: CheckoutReducerState,
    action: PayloadAction<{ status: CheckoutStatus }>,
  ) {
    if (state.checkoutSummary) {
      state.checkoutSummary.status = action.payload.status
    }
  },
}

export default checkoutReducers
