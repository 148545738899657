import { useState, useEffect } from 'react'

enum DeviceSize {
  Mobile,
  Desktop,
  LargeDesktop,
}

function detectDeviceSize() {
  if (typeof window === 'undefined') {
    return DeviceSize.Desktop
  }

  const width = window.innerWidth
  if (width < 768) {
    return DeviceSize.Mobile
  }

  if (width < 1400) {
    return DeviceSize.Desktop
  }

  return DeviceSize.LargeDesktop
}

export default function useDeviceSize() {
  const [deviceType, setDeviceType] = useState<DeviceSize>(detectDeviceSize())

  useEffect(() => {
    function handleResize() {
      setDeviceType(detectDeviceSize())
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    isMobile: deviceType === DeviceSize.Mobile,
    isDesktop: deviceType === DeviceSize.Desktop,
    isLargeDesktop: deviceType === DeviceSize.LargeDesktop,
  }
}
