import { useController, useFormContext } from 'react-hook-form'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'
import useTranslation from 'next-translate/useTranslation'
import getCheckoutRequestItemFromCheckoutItem from '@ancon/wildcat-utils/checkout/getCheckoutRequestItemFromCheckoutItem'
import { CheckoutRequestItem } from '@ancon/wildcat-utils/checkout/types'
import { useRouter } from 'next/router'

import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutItemsCountSelector,
  checkoutItemsSelector,
} from '../../../checkout/store/checkoutSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  deleteCheckout,
  updateCheckout,
} from '../../../checkout/store/checkoutThunks'
import {
  CheckoutOutOfStockFormState,
  OutOfStockCheckoutItem,
  StockErrorAdditionalDataMap,
} from '../../types'
import getOutOfStockFormInitialValues from '../../utils/getOutOfStockFormInitialValues'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletSelectedOutletHasCheckoutSelector } from '../../../outlet/store/outletSelector'
import useRouteMatch from '../../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../../app/constants'

import styles from './CheckoutOutOfStockFormFooter.module.scss'

function getIsRemoveAllCheckoutItems(
  items: OutOfStockCheckoutItem[],
  checkoutItemsCount: number,
) {
  const removedItemsCount = items.filter(
    item => item.currentQuantity === 0,
  ).length
  const isDeleteCheckout = removedItemsCount === checkoutItemsCount

  return isDeleteCheckout
}

type CheckoutOutOfStockFormFooterProps = {
  onSuccess: ({ isCheckoutDeleted }: { isCheckoutDeleted?: boolean }) => void
}

export default function CheckoutOutOfStockFormFooter({
  onSuccess,
}: CheckoutOutOfStockFormFooterProps) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const {
    watch,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useFormContext<CheckoutOutOfStockFormState>()
  const [isAllItemsOutOfStock, outOfStockItems] = watch([
    'isAllItemsOutOfStock',
    'items',
  ])
  const outletSelectedOutletHasCheckout = useAppSelector(
    outletSelectedOutletHasCheckoutSelector,
  )
  const checkoutItemsCount = useAppSelector(checkoutItemsCountSelector)
  const isOutletPage = useRouteMatch([AppRoutes.Outlet])
  const isDeleteCheckout = getIsRemoveAllCheckoutItems(
    outOfStockItems,
    checkoutItemsCount,
  )

  function stocksValidator(stocksMap: StockErrorAdditionalDataMap) {
    const isStockError = Object.values(stocksMap).some(
      variantStock => variantStock.addedCount > variantStock.count,
    )
    return !isStockError
  }

  const {
    fieldState: { error },
  } = useController({
    name: 'stocks',
    ...(!isAllItemsOutOfStock && {
      rules: {
        validate: stocksValidator,
      },
    }),
  })

  async function handleUpdateAndContinue(values: CheckoutOutOfStockFormState) {
    const { items } = values

    const updatedItemsMap = items.reduce(
      (acc, item) => {
        const { currentQuantity, id } = item
        acc[id] = currentQuantity
        return acc
      },
      {} as Record<string, number>,
    )

    const checkoutItems = checkoutItemsSelector(store.getState())

    const updatedCheckoutItems = checkoutItems.reduce((acc, item) => {
      const { id, quantity } = item
      const updatedItemsMapQuantity = updatedItemsMap[id]

      if (updatedItemsMapQuantity === 0) return acc

      const newItem = {
        ...item,
        quantity: updatedItemsMap[id] || quantity,
      }
      if (newItem.quantity) {
        const checkoutRequestItem =
          getCheckoutRequestItemFromCheckoutItem(newItem)

        acc.push(checkoutRequestItem)
      }

      return acc
    }, [] as CheckoutRequestItem[])

    if (updatedCheckoutItems.length) {
      await dispatch(updateCheckout({ items: updatedCheckoutItems }))
        .unwrap()
        .then(() => {
          onSuccess({})
        })
        .catch(err => {
          if (err?.stockErrorAdditionalData) {
            const newValues = getOutOfStockFormInitialValues(
              err.stockErrorAdditionalData,
              checkoutItems,
            )

            reset(newValues)
          }
        })
    } else {
      await dispatch(deleteCheckout())
        .unwrap()
        .then(() => {
          onSuccess({ isCheckoutDeleted: true })
        })
    }
  }

  function redirectToOutletPage() {
    const outletId = checkoutCurrentCheckoutOutletIdSelector(store.getState())

    router.push(`/outlets/${outletId}`)
  }

  function getActionButtonText() {
    if (isDeleteCheckout) {
      return t('cartOutOfStockModal.deleteOrder')
    }

    if (isAllItemsOutOfStock) {
      return t('cartOutOfStockModal.removeAndContinue')
    }

    return t('cartOutOfStockModal.updateAndContinue')
  }

  return (
    <div className={styles.footer}>
      {isAllItemsOutOfStock &&
        !(outletSelectedOutletHasCheckout && isOutletPage) && (
          <Button
            variant="secondary"
            size="large"
            onClick={redirectToOutletPage}
          >
            {t('cartOutOfStockModal.chooseSomethingElse')}
          </Button>
        )}
      <Button
        loading={isSubmitting}
        disabled={isSubmitting || !isValid || !!error}
        onClick={handleSubmit(handleUpdateAndContinue)}
        size="large"
      >
        {getActionButtonText()}
      </Button>
    </div>
  )
}
