import clsx from 'clsx'

import Button from '../Button'

import DropdownIcon from './DropdownIcon'
import styles from './DropdownButton.module.scss'

export type DropdownButtonProps = {
  /** Button className */
  className?: string
  /** Whether dropdown is opened */
  isOpen: boolean
  /** Selected option label */
  label?: string
  /** Selected option label className */
  labelClassName?: string
  /** Callback function to handle toggle */
  onToggle?: () => void
}

export default function DropdownButton({
  className,
  isOpen,
  label,
  labelClassName,
  onToggle,
}: DropdownButtonProps) {
  return (
    <Button className={clsx(styles.button, className)} onClick={onToggle} plain>
      {label && <span className={labelClassName}>{label}</span>}
      <DropdownIcon isOpen={isOpen} />
    </Button>
  )
}
