import { OrderFormat, DayOfWeek } from '@ancon/wildcat-types'

export const SupportedLanguage = {
  English: 'en',
  Swedish: 'sv',
  Norwegian: 'nb',
}

export const LanguageOptions = [
  {
    label: 'English',
    value: SupportedLanguage.English,
  },
  {
    label: 'Svenska',
    value: SupportedLanguage.Swedish,
  },
  {
    label: 'Norsk',
    value: SupportedLanguage.Norwegian,
  },
]

export const DefaultLanguage = SupportedLanguage.Swedish

export enum PortalHostId {
  Modal = 'ModalsPortal',
  Toast = 'ToastsPortal',
}

export enum AppRoutes {
  Home = '/',
  Outlets = '/outlets',
  Outlet = '/outlet',
  Checkout = '/checkout',
  OrderStatus = '/order-status',
  Account = '/account',
  Orders = '/orders',
  GiftCard = '/giftcard',
}

/*
  NonOrderRoutes are routes that doesn't related to order process.
  - GiftCard: Preview of gift card
*/
export const NonOrderRoutes = [AppRoutes.GiftCard]

export const OrderFormatUrlSlug = {
  none: OrderFormat.None,
  'eat-in': OrderFormat.EatIn,
  'take-away': OrderFormat.TakeAway,
  'table-order': OrderFormat.TableOrder,
  delivery: OrderFormat.Delivery,
  'drive-thru': OrderFormat.DriveThru,
}

export const GoogleMapsAPIUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places,geocoding,geometry&callback=Function.prototype`

export const DaysOfWeekMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.Everyday,
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
}

export const OrderFormatSlugMap = {
  [OrderFormat.None]: 'none',
  [OrderFormat.EatIn]: 'eat-in',
  [OrderFormat.TakeAway]: 'take-away',
  [OrderFormat.TableOrder]: 'table-order',
  [OrderFormat.Delivery]: 'delivery',
  [OrderFormat.DriveThru]: 'drive-thru',
}

export const NotAvailable = 'N/A'

export const StockholmCoordinates = {
  latitude: 59.32932349999999,
  longitude: 18.0685808,
}
