import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  signInAnonymously as firebaseSignInAnonymously,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  getAdditionalUserInfo,
  linkWithCredential,
  sendPasswordResetEmail,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import getT from 'next-translate/getT'
import getFullName from '@ancon/wildcat-utils/user/getFullName'
import { ErrorCode, SignInProvider } from '@ancon/wildcat-types'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import firebaseAuth from '../../firebase/firebaseAuth'
import getTranslatedAuthError from '../utils/getTranslatedAuthError'
import { FirebaseUser } from '../../firebase/types'
import serializeFirebaseUser from '../../firebase/utils/serializeFirebaseUser'
import {
  clientContextCustomerIdSelector,
  clientContextSelector,
} from '../../clientContext/store/clientContextSelectors'
import api from '../../../api'
import {
  activateCustomer,
  fetchClientContext,
  updateCustomerDetails,
} from '../../clientContext/store/clientContextThunks'
import {
  CompanySignUpFormState,
  SignInWithActionPayload,
  UserRegistrationData,
  UserUpdateData,
} from '../types'
import { NotAvailable } from '../../app/constants'
import AppleAuthProvider from '../../firebase/AppleAuthProvider'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { outletSelectedOutletTenantIdSelector } from '../../outlet/store/outletSelector'
import { appShowError } from '../../app/store/appSlice'
import getIsAuthCredentialsError from '../utils/getIsAuthCredentialError'
import { CodedError } from '../../app/types'
import getLocaleTag from '../../app/utils/getLocalTag'
import getTenantIds from '../../app/utils/getTenantIds'
import isAppWhiteLabeled from '../../app/utils/isAppWhiteLabeled'

const signInWithEmailAndPassword = createAppAsyncThunk<
  void,
  {
    email: string
    password: string
  }
>(
  'auth/signInWithEmailAndPassword',
  async ({ email, password }, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await firebaseSignInWithEmailAndPassword(firebaseAuth, email, password)

      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignInFailure.title'),
          message: t('errors.authGenericSignInFailure.message'),
        },
        locale,
      )

      return rejectWithValue(authError)
    }
  },
)

export const signInAnonymously = createAppAsyncThunk<FirebaseUser>(
  'auth/signInAnonymously',
  async (_, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      const { user } = await firebaseSignInAnonymously(firebaseAuth)
      return serializeFirebaseUser(user)
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericGuestSignInFailure.title'),
          message: t('errors.authGenericGuestSignInFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

export const registerWithUserDetails = createAppAsyncThunk<
  void,
  UserRegistrationData
>(
  'auth/registerWithUserDetails',
  async (
    { email, password, firstName, lastName, telephoneNumber, languageCode },
    { dispatch, getState, rejectWithValue },
  ) => {
    const locale = appLanguageSelector(getState())

    const customerId = clientContextCustomerIdSelector(getState())
    const clientContext = clientContextSelector(getState())

    const t = await getT(locale, 'common')

    const userData = {
      firstName,
      lastName,
      email,
      telephoneNumber,
      languageCode,
    }

    let firebaseAccountLinked = false

    try {
      const isAnonymous = firebaseAuth.currentUser?.isAnonymous

      if (customerId && isAnonymous) {
        // Create credentials for email and password
        const credentials = EmailAuthProvider.credential(email, password)

        /** Link the credentials to the anonymous user */
        const firebaseUser = firebaseAuth.currentUser
        if (firebaseUser) {
          await linkWithCredential(firebaseUser, credentials)
          firebaseAccountLinked = true
        }

        // Force refresh the idToken
        await firebaseAuth.currentUser?.getIdToken(true)

        // Update customer details & activate
        await dispatch(updateCustomerDetails(userData))
        await dispatch(activateCustomer({ email }))
      } else {
        if (isAnonymous) {
          /** Create user with email and password credentials in Firebase & Ancon */
          await createUserWithEmailAndPassword(firebaseAuth, email, password)
          await api.user.customers.post.create(userData)
        } else if (clientContext?.isCustomer && email) {
          await dispatch(updateCustomerDetails(userData))

          // Activate the user if customer has no email
          if (!clientContext?.customer?.email) {
            await dispatch(activateCustomer({ email }))
          }
        } else {
          await api.user.customers.post.create(userData)
        }

        // Force refresh the idToken
        await firebaseAuth.currentUser?.getIdToken(true)
      }

      const firebaseUser = firebaseAuth.currentUser

      if (firebaseUser) {
        const updatedProfile = { displayName: getFullName(userData) }

        // Update firebase user's display name
        await updateProfile(firebaseUser, updatedProfile)
      }

      await dispatch(fetchClientContext())

      return undefined
    } catch (err: any) {
      const isAuthCredentialsError = getIsAuthCredentialsError(err)
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignUpFailure.title'),
          message: t('errors.authGenericSignUpFailure.message'),
        },
        locale,
      )

      if (
        (err as unknown as CodedError)?.code !==
          ErrorCode.AuthNetworkRequestFailed &&
        firebaseAccountLinked
      ) {
        try {
          await firebaseAuth.currentUser?.delete()
        } catch (error) {
          if (
            (error as unknown as CodedError)?.code ===
            ErrorCode.AuthRecentLoginRequiredException
          ) {
            await firebaseAuth.signOut()
          }
        }

        await dispatch(signInAnonymously())
      }

      return rejectWithValue({ ...authError, isAuthCredentialsError })
    }
  },
)

export const registerSocialAuthCustomer = createAppAsyncThunk<
  void,
  {
    userCredential: UserCredential
  }
>(
  'auth/registerSocialAuthCustomer',
  async ({ userCredential }, { getState, dispatch }) => {
    const additionalUserInfo = getAdditionalUserInfo(userCredential)

    const language = appLanguageSelector(getState())

    if (additionalUserInfo?.isNewUser) {
      const firebaseUser = userCredential.user

      const { displayName, email, phoneNumber } = firebaseUser

      const [firstName, lastName] = displayName?.split(/\s+/) ?? []

      const userRegistrationData: UserRegistrationData = {
        firstName: firstName ?? NotAvailable,
        lastName: lastName ?? NotAvailable,
        email: email ?? NotAvailable,
        password: '',
        telephoneNumber: phoneNumber ?? NotAvailable,
        languageCode: getLocaleTag(language) ?? null,
      }

      await dispatch(registerWithUserDetails(userRegistrationData))

      // Force refresh firebase token after registration
      await firebaseAuth.currentUser?.getIdToken(true)
    }
  },
)

const signInWithGoogle = createAppAsyncThunk<void>(
  'auth/signInWithGoogle',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      // Create a Google authentication provider
      const googleAuthProvider = new GoogleAuthProvider()

      // Request Google permissions and get user credentials
      const googleUserCredential = await signInWithPopup(
        firebaseAuth,
        googleAuthProvider,
      )

      await dispatch(
        registerSocialAuthCustomer({
          userCredential: googleUserCredential,
        }),
      )

      return undefined
    } catch (error) {
      const authError = await getTranslatedAuthError(
        error,
        {
          title: t('errors.authGenericSignWithGoogleFailure.title'),
          message: t('errors.authGenericSignWithGoogleFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

const signInWithFacebook = createAppAsyncThunk<void>(
  'auth/signInWithFacebook',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      // Create a Facebook authentication provider
      const facebookAuthProvider = new FacebookAuthProvider()

      // Request Facebook permissions and get user credentials
      const facebookUserCredential = await signInWithPopup(
        firebaseAuth,
        facebookAuthProvider,
      )

      await dispatch(
        registerSocialAuthCustomer({
          userCredential: facebookUserCredential,
        }),
      )

      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignWithFacebookFailure.title'),
          message: t('errors.authGenericSignWithFacebookFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

const signInWithApple = createAppAsyncThunk<void>(
  'auth/signInWithApple',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      // Create an Apple authentication provider
      const appleAuthProvider = new AppleAuthProvider()

      // Request Apple permissions and get user credentials
      const appleUserCredential = await signInWithPopup(
        firebaseAuth,
        appleAuthProvider,
      )

      await dispatch(
        registerSocialAuthCustomer({
          userCredential: appleUserCredential,
        }),
      )

      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignWithAppleFailure.title'),
          message: t('errors.authGenericSignWithAppleFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

export const signInWith = createAppAsyncThunk<void, SignInWithActionPayload>(
  'auth/signInWith',
  async ({ signInProvider, credential }, { dispatch, rejectWithValue }) => {
    try {
      switch (signInProvider) {
        case SignInProvider.Google:
          return await dispatch(signInWithGoogle()).unwrap()

        case SignInProvider.Facebook:
          return await dispatch(signInWithFacebook()).unwrap()

        case SignInProvider.Apple:
          return await dispatch(signInWithApple()).unwrap()

        case SignInProvider.EmailPassword:
        default:
          return await dispatch(
            signInWithEmailAndPassword(credential!),
          ).unwrap()
      }
    } catch (authError) {
      return rejectWithValue(authError)
    }
  },
)

export const signOut = createAppAsyncThunk<void>(
  'auth/signOut',
  async (_, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await firebaseAuth.signOut()
      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignOutFailure.title'),
          message: t('errors.authGenericSignOutFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

export const resetPassword = createAppAsyncThunk<void, { email: string }>(
  'auth/resetPassword',
  async ({ email }, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      return await sendPasswordResetEmail(firebaseAuth, email)
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericResetPasswordFailure.title'),
          message: t('errors.authGenericResetPasswordFailure.message'),
        },
        locale,
      )
      return rejectWithValue(authError)
    }
  },
)

export const registerWithGuestDetails = createAppAsyncThunk<
  void,
  UserUpdateData
>(
  'auth/registerWithGuestDetails',
  async (
    { firstName, lastName, telephoneNumber, languageCode },
    { dispatch, rejectWithValue, getState },
  ) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      const userData = {
        firstName,
        lastName,
        telephoneNumber,
        languageCode,
        email: null,
      }

      await api.user.customers.post.create(userData)
      await firebaseAuth.currentUser?.getIdToken(true) // Force refresh the idToken

      await dispatch(fetchClientContext())
      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authRegisterGuestDetailsFailure.title'),
          message: t('errors.authRegisterGuestDetailsFailure.message'),
        },
        locale,
      )

      return rejectWithValue(authError)
    }
  },
)

export const updateAccountDetails = createAppAsyncThunk<void, UserUpdateData>(
  'auth/updateAccountDetails',
  async (
    { firstName, lastName, telephoneNumber, languageCode },
    { dispatch, getState, rejectWithValue },
  ) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      const customerId = clientContextCustomerIdSelector(getState())!

      await api.user.customers.put.byId(
        {
          firstName,
          lastName,
          telephoneNumber,
          languageCode,
        },
        {
          customerId,
        },
      )
      dispatch(fetchClientContext())

      return undefined
    } catch (err) {
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authRegisterGuestDetailsFailure.title'),
          message: t('errors.authRegisterGuestDetailsFailure.message'),
        },
        locale,
      )

      return rejectWithValue(authError)
    }
  },
)

export const linkCredentialsToGuestUser = createAppAsyncThunk<
  void,
  {
    email: string
    password: string
  }
>(
  'auth/linkCredentialsToGuestUser',
  async ({ email, password }, { dispatch, getState }) => {
    const credentials = EmailAuthProvider.credential(email, password)
    const customerId = clientContextCustomerIdSelector(getState())!

    /** Link the credentials to the anonymous user */
    const firebaseUser = firebaseAuth.currentUser
    if (firebaseUser) {
      await linkWithCredential(firebaseUser, credentials)
    }

    await api.user.customers.put.activate({ email }, { customerId })

    await firebaseAuth.currentUser?.getIdToken(true) // Force refresh the idToken
    await dispatch(fetchClientContext())

    return undefined
  },
)

export const registerAsCompanyUser = createAppAsyncThunk<
  void,
  CompanySignUpFormState
>(
  'auth/registerAsCompanyUser',
  async (
    {
      email,
      password,
      firstName,
      lastName,
      telephoneNumber,
      companyName,
      businessNumber,
      street,
      zipCode,
      city,
      country,
    },
    { getState, dispatch },
  ) => {
    const locale = appLanguageSelector(getState())
    const tenantIds = getTenantIds(false)
    const isWhiteLabeled = isAppWhiteLabeled()
    const tenantId = outletSelectedOutletTenantIdSelector(getState())!

    let firebaseAccountLinked = false

    try {
      // Create credentials for email and password
      const credentials = EmailAuthProvider.credential(email, password)

      // To avoid throwing FirebaseAuthRecentLoginRequiredException when linking credentials
      await firebaseAuth.currentUser?.getIdToken(true)

      /** Link the credentials to the anonymous user */
      const firebaseUser = firebaseAuth.currentUser
      if (firebaseUser) {
        await linkWithCredential(firebaseUser, credentials)
        firebaseAccountLinked = true
      }

      const userData = {
        firstName,
        lastName,
        languageCode: getLocaleTag(locale),
        telephoneNumber,
      }

      const companyData = {
        companyName,
        businessNumber,
        companyAddress: {
          street,
          zipCode,
          city,
        },
        countryCode: country || null,
        tenantId: isWhiteLabeled ? tenantIds[0] : tenantId,
      }

      await api.user.customers.post.create({
        ...userData,
        company: companyData,
        email,
      })

      // Force refresh the idToken
      await firebaseAuth.currentUser?.getIdToken(true)

      if (firebaseUser) {
        const updatedProfile = { displayName: companyName }

        // Update firebase user's display name
        await updateProfile(firebaseUser, updatedProfile)
      }

      await dispatch(fetchClientContext())
    } catch (err: any) {
      const t = await getT(locale, 'common')
      const authError = await getTranslatedAuthError(
        err,
        {
          title: t('errors.authGenericSignUpFailure.title'),
          message: t('errors.authGenericSignUpFailure.message'),
        },
        locale,
      )

      if (firebaseAccountLinked) {
        firebaseAuth.currentUser?.delete().catch(error => {
          if (error?.code === ErrorCode.AuthRecentLoginRequiredException) {
            firebaseAuth.signOut()
          }
        })
      }

      dispatch(appShowError(authError))
    }
  },
)
