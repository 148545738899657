import { ErrorCode } from '@ancon/wildcat-types'
import getTranslation from 'next-translate/getT'

import { AppError } from '../../app/types'
import { getTranslatedError } from '../../app/utils/getTranslatedError'

type CodedError = {
  code?: string
}

function isCodedError(error: unknown): error is CodedError {
  return !!(error as CodedError)?.code
}

export default async function getTranslatedAuthError(
  error: unknown,
  fallback: AppError,
  locale?: string,
) {
  const t = await getTranslation(locale, 'common')

  const authErrors: Partial<Record<ErrorCode, AppError>> = {
    [ErrorCode.AuthEmailAlreadyInUse]: {
      title: t('errors.authEmailAlreadyInUse.title'),
      message: t('errors.authEmailAlreadyInUse.message'),
    },
    [ErrorCode.AuthInvalidEmail]: {
      title: t('errors.authInvalidEmail.title'),
      message: t('errors.authInvalidEmail.message'),
    },
    [ErrorCode.AuthWrongPassword]: {
      title: t('errors.authWrongPassword.title'),
      message: t('errors.authWrongPassword.message'),
    },
    [ErrorCode.AuthUserNotFound]: {
      title: t('errors.authUserNotFound.title'),
      message: t('errors.authUserNotFound.message'),
    },
    [ErrorCode.AuthTooManyAttempts]: {
      title: t('errors.authTooManyRequests.title'),
      message: t('errors.authTooManyRequests.message'),
    },
    [ErrorCode.AuthAccountExistWithAnotherProvider]: {
      title: t('errors.authUseDifferentMethod.title'),
      message: t('errors.authUseDifferentMethod.message'),
    },
    [ErrorCode.AuthNetworkRequestFailed]: {
      title: t('errors.networkError.title'),
      message: t('errors.networkError.message'),
    },
    [ErrorCode.CompanyEmailAlreadyInUse]: {
      title: t('errors.companyEmailAlreadyInUse.title'),
      message: t('errors.companyEmailAlreadyInUse.message'),
    },
  }

  if (isCodedError(error)) {
    return authErrors[error.code as ErrorCode] ?? fallback
  }

  const translatedError = await getTranslatedError(error, fallback, locale)

  return translatedError
}
