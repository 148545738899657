import { createUseSignalRHook } from '@ancon/wildcat-utils/signalr'
import Logger from '@ancon/wildcat-utils/logger/Logger'

import isProduction from '../../app/utils/isAppProduction'
import { appIsSignalROnlineSelector } from '../../app/store/appSelectors'
import { appSetIsSignalROnline } from '../../app/store/appSlice'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import useAppStore from '../../../store/hooks/useAppStore'

const useSignalR = createUseSignalRHook(
  {
    signalRApiUrl: process.env.NEXT_PUBLIC_NOTIFICATION_API_URL,
    logger: new Logger({
      execute(logLevel, message, data) {
        // eslint-disable-next-line no-console
        console.log(`[SignalR] ${message}`, data)
      },
    }),
    enableLogging: !isProduction(),
  },
  {
    isSignalROnlineSelector: appIsSignalROnlineSelector,
  },
  {
    setIsSignalROnlineAction: appSetIsSignalROnline,
  },
  {
    useAppDispatch,
    useAppSelector,
    useAppStore,
  },
)

export default useSignalR
