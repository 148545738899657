import useAppSelector from '../../../store/hooks/useAppSelector'
import { AppRoutes } from '../../app/constants'
import useDeviceSize from '../../app/hooks/useDeviceSize'
import useRouteMatch from '../../app/hooks/useRouteMatch'
import { appIsCartDrawerVisibleSelector } from '../../app/store/appSelectors'
import { checkoutCurrentCheckoutTotalItemCountSelector } from '../store/checkoutSelectors'

export default function useCartDrawerOpen() {
  const { isLargeDesktop } = useDeviceSize()
  const cartItemCount = useAppSelector(
    checkoutCurrentCheckoutTotalItemCountSelector,
  )
  const isCartDrawerVisible = useAppSelector(appIsCartDrawerVisibleSelector)
  const isCartDisabledRoute = useRouteMatch([AppRoutes.Checkout])
  const cartHasItems = cartItemCount && cartItemCount > 0

  return (
    cartHasItems &&
    !isCartDisabledRoute &&
    (isLargeDesktop || isCartDrawerVisible)
  )
}
