import { useRouter } from 'next/router'

export default function useRouteMatch(routes: string[]) {
  const router = useRouter()

  return routes.some(candidateRoute => {
    if (candidateRoute === '/') {
      return router.asPath === '/'
    }

    return router.asPath.startsWith(candidateRoute)
  })
}
