import clsx from 'clsx'

import { checkoutCurrentCheckoutDetailsSelector } from '../store/checkoutSelectors'
import useAppSelector from '../../../store/hooks/useAppSelector'
import useCartDrawerOpen from '../hooks/useCartDrawerOpen'

import ShoppingCart from './shoppingCart/ShoppingCart'
import styles from './CartDrawer.module.scss'

export default function CartDrawer() {
  const isCartDrawerVisible = useCartDrawerOpen()
  const checkout = useAppSelector(checkoutCurrentCheckoutDetailsSelector)

  if (!checkout) return null

  return (
    <div
      className={clsx(styles.container, {
        [styles.isOpen]: isCartDrawerVisible,
      })}
    >
      <ShoppingCart />
    </div>
  )
}
