import { useCallback } from 'react'
import OneSignal from 'react-onesignal'
import { useRouter } from 'next/router'

import {
  createOrUpdateMessagingProvider,
  initializeOneSignal,
  promptForPushNotificationsWithUserResponse,
} from '../store/notificationThunks'
import {
  setNotificationPermissionGranted,
  setNotificationSubscribed,
} from '../store/notificationSlice'
import {
  isNotificationPermissionGrantedSelector,
  isNotificationSubscribedSelector,
  isOneSignalInitializedSelector,
} from '../store/notificationSelectors'
import useAppStore from '../../../store/hooks/useAppStore'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import getOneSignalAppId from '../../app/utils/getOneSignalAppId'
import { NotificationCheckoutData, OneSignalEventName } from '../types'

const oneSignalAppId = getOneSignalAppId()

function useOneSignal() {
  const store = useAppStore()

  const dispatch = useAppDispatch()

  const router = useRouter()

  const init = useCallback(
    async (customerId: string) => {
      function handleSubscription(nextSubscribed: boolean) {
        if (
          nextSubscribed !== isNotificationSubscribedSelector(store.getState())
        ) {
          // Keep for logging purposes
          // eslint-disable-next-line no-console
          console.log('OneSignal: Subscription:', nextSubscribed)

          dispatch(setNotificationSubscribed(nextSubscribed))

          OneSignal.getUserId(userId => {
            // Keep for logging purposes
            // eslint-disable-next-line no-console
            console.log('OneSignal: User ID', userId)

            if (userId) {
              dispatch(createOrUpdateMessagingProvider(userId))
            }
          })
        }
      }

      function handlePermission(permission: NotificationPermission) {
        const nextPermission = permission === 'granted'

        if (
          nextPermission !==
          isNotificationPermissionGrantedSelector(store.getState())
        ) {
          // Keep for logging purposes
          // eslint-disable-next-line no-console
          console.log('OneSignal: Push permission:', nextPermission)

          dispatch(setNotificationPermissionGranted(nextPermission))
        }
      }

      function handleNotificationOpened(notification: Notification) {
        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: notification opened:', notification)

        const { outletId, checkoutId } = (notification.data ??
          {}) as NotificationCheckoutData

        if (outletId && checkoutId) {
          router.replace({
            pathname: '/order-status/[outletId]/[checkoutId]',
            query: {
              outletId,
              checkoutId,
            },
          })
        }

        // https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened-event
        OneSignal.addListenerForNotificationOpened(handleNotificationOpened)
      }

      function handlePermissionChange(changeEvent: {
        to: NotificationPermission
      }) {
        const permission = changeEvent.to

        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: Push permission changed:', permission)

        handlePermission(permission)
      }

      function handleSubscriptionChange(subscribed: boolean) {
        // Keep for logging purposes
        // eslint-disable-next-line no-console
        console.log('OneSignal: Subscription changed:', subscribed)

        handleSubscription(subscribed)
      }

      if (oneSignalAppId) {
        const isInitialized = isOneSignalInitializedSelector(store.getState())
        if (!isInitialized) {
          await dispatch(initializeOneSignal(customerId))
        }

        OneSignal.getNotificationPermission(handlePermission)
        OneSignal.on(
          OneSignalEventName.NotificationPermissionChange,
          handlePermissionChange,
        )

        OneSignal.getSubscription(handleSubscription)
        OneSignal.on(
          OneSignalEventName.SubscriptionChange,
          handleSubscriptionChange,
        )

        OneSignal.addListenerForNotificationOpened(handleNotificationOpened)
      }

      return () => {
        OneSignal.off(OneSignalEventName.SubscriptionChange, handleSubscription)

        OneSignal.off(
          OneSignalEventName.NotificationPermissionChange,
          handlePermissionChange,
        )
      }
    },
    [dispatch, router, store],
  )

  const promptIfNeeded = useCallback(async () => {
    if (oneSignalAppId) {
      return dispatch(promptForPushNotificationsWithUserResponse())
    }

    return false
  }, [dispatch])

  return { init, promptIfNeeded }
}

export default useOneSignal
