import { useFormContext } from 'react-hook-form'
import { useState } from 'react'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'
import useTranslation from 'next-translate/useTranslation'
import ChevronRightIcon from '@ancon/wildcat-ui/shared/icons/chevron-right.svg'
import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'

import { SignUpFormState, SignUpStep, UserRegistrationData } from '../types'
import Alert from '../../app/components/Alert'
import AgreeToTermsCheckBox from '../components/AgreeToTermsCheckBox'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { registerWithUserDetails } from '../store/authThunks'
import { Toast } from '../../notification'
import { hideAuthModal } from '../store/authSlice'
import getLocaleTag from '../../app/utils/getLocalTag'

import LoginFormInputs from './LoginFormInputs'
import UserInfoFields from './UserInfoFields'
import styles from './RegistrationForm.module.scss'

type RegistrationFormProps = {
  isIncompleteCustomerSignUp?: boolean
  onSuccess?: () => void
}

export default function RegistrationForm({
  isIncompleteCustomerSignUp,
  onSuccess,
}: RegistrationFormProps) {
  const { t, lang } = useTranslation('common')
  const dispatch = useAppDispatch()
  const {
    watch,
    formState: { errors, isSubmitting, isValid },
    setValue,
    handleSubmit,
  } = useFormContext<SignUpFormState>()
  const [currentStep, agreedToTerms, email, password] = watch([
    'currentStep',
    'agreedToTerms',
    'email',
    'password',
  ])
  const [signUpError, setSignUpError] = useState<string | null>(null)

  function handleClearError() {
    setSignUpError(null)
  }

  function handleGoNextStep() {
    if (signUpError) {
      handleClearError()
    }
    setValue('currentStep', SignUpStep.UserDetails)
  }

  function handleSignUpSuccess() {
    Toast.success(
      t('components.navBar.auth.signUp'),
      t('components.navBar.auth.signedUpSuccess'),
    )

    if (!isIncompleteCustomerSignUp) {
      dispatch(hideAuthModal())
    }

    onSuccess?.()
  }

  function handleOnClickBack() {
    setValue('currentStep', SignUpStep.Credentials)
  }

  async function handleSignUp(values: SignUpFormState) {
    const userDetails: UserRegistrationData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      telephoneNumber: values.phoneNumber,
      languageCode: getLocaleTag(lang),
    }

    if (signUpError) {
      handleClearError()
    }

    await dispatch(registerWithUserDetails(userDetails))
      .unwrap()
      .then(handleSignUpSuccess)
      .catch(error => {
        if (error?.isAuthCredentialsError && !isIncompleteCustomerSignUp) {
          handleOnClickBack()
        }
        setSignUpError(error.message)
      })
  }

  const hasValidCredentials =
    !!email && !!password && !errors.email && !errors.password
  const nextStepDisabled = !hasValidCredentials || !agreedToTerms

  function getCurrentStep() {
    switch (currentStep) {
      case SignUpStep.UserDetails:
        return (
          <>
            {!isIncompleteCustomerSignUp && (
              <Button
                variant="secondary"
                className={styles.backButton}
                onClick={handleOnClickBack}
              >
                <BackIcon />
              </Button>
            )}
            <UserInfoFields enableEmailField={isIncompleteCustomerSignUp} />
            <Button
              size="large"
              onClick={handleSubmit(handleSignUp)}
              loading={isSubmitting}
              disabled={!isValid}
              className={styles.signUpButton}
            >
              {t('components.navBar.auth.signMeUp')}
            </Button>
          </>
        )

      case SignUpStep.Credentials:
      default:
        return (
          <>
            <LoginFormInputs />
            <AgreeToTermsCheckBox />
            <Button
              size="large"
              onClick={handleGoNextStep}
              disabled={nextStepDisabled}
              className={styles.nextButton}
            >
              <span>{t('components.navBar.auth.next')}</span>
              <ChevronRightIcon />
            </Button>
          </>
        )
    }
  }

  return (
    <>
      <Alert
        className={styles.alert}
        isOpen={!!signUpError}
        toggle={handleClearError}
        type="danger"
      >
        {signUpError}
      </Alert>
      {getCurrentStep()}
    </>
  )
}
