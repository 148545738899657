import ChevronDownFillIcon from '@ancon/wildcat-ui/shared/icons/chevron-down-fill.svg'
import clsx from 'clsx'

import styles from './DropdownIcon.module.scss'

type DropdownIconProps = {
  /** Container className */
  className?: string
  /** Whether dropdown is opened */
  isOpen: boolean
}

export default function DropdownIcon({ className, isOpen }: DropdownIconProps) {
  return (
    <span
      className={clsx(
        styles.container,
        {
          [styles.rotate180]: isOpen,
          [styles.rotate0]: !isOpen,
        },
        className,
      )}
    >
      <ChevronDownFillIcon />
    </span>
  )
}
