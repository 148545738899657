import {
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'

import Dropdown, { DropdownOption } from './dropdown/Dropdown'
import styles from './FormDropdown.module.scss'
import BodyText from './BodyText'
import FormInputErrorLabel from './FormInputErrorLabel'

type ReactHookFormRules = UseControllerProps['rules']

type FormDropdownProps = {
  /* Name of the field in form state */
  fieldName: string
  /* Text label of the input */
  label?: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Customized Error Message */
  options: DropdownOption[]
}

export default function FormDropdown({
  label,
  options,
  fieldName,
  rules,
}: FormDropdownProps) {
  const { t } = useTranslation('common')
  const { setValue } = useFormContext()

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: fieldName,
    rules,
  })

  const hasError = !!error

  function handleOnClickOption(newValue: string) {
    onChange(newValue)
    setValue(fieldName, newValue)
  }

  const selectedOption = options.find(option => option.value === value)

  return (
    <div className={styles.container}>
      {label && (
        <BodyText as="label" color="body-1" className={styles.label}>
          {label}
        </BodyText>
      )}
      <Dropdown
        label={selectedOption?.label || t('components.dropdown.select')}
        options={options}
        onClickOption={handleOnClickOption}
        buttonClassName={styles.dropdownButton}
        popupClassName={styles.dropdownPopup}
        labelClassName={clsx({ [styles.placeholder]: !selectedOption?.label })}
        value={selectedOption}
      />
      {hasError ? (
        <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
      ) : (
        <div className={styles.spacer} />
      )}
    </div>
  )
}
