import { APIGetRequest, IntegrationType } from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'

type IntegrationsAPI = {
  get: {
    activeCheckoutPayment: APIGetRequest<
      {
        integration: {
          id: string
          type: IntegrationType
          name: string
          settings: {
            merchantId?: string
            useBetaOnline?: boolean
            useBetaTerminal?: boolean
          }
        }
      },
      { outletId: string }
    >
  }
}

const integrations: IntegrationsAPI = {
  get: {
    activeCheckoutPayment: new Request(
      endpoints.core.outlets.byId.integrations.activeCheckoutPayment.list,
    ).get,
  },
}

export default integrations
