import { useEffect } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../store/hooks/useAppSelector'
import Modal from '../../app/components/modal/Modal'
import {
  productDetailsPendingSelector,
  productHasDetailsSelector,
  productSelectedIdSelector,
  productsIsProductDetailsModalOpenSelector,
} from '../store/productSelectors'
import { fetchProductDetails } from '../store/productThunks'
import ProductConfigureForm from '../forms/ProductConfigureForm'
import { ConfigureProductFormState } from '../types'

import styles from './ProductDetailsModal.module.scss'
import ProductDetailsModalSkeleton from './ProductDetailsModalSkeleton'

type ProductDetailsModalProps = {
  onSubmit: (values: ConfigureProductFormState) => void
  onDismiss: () => void
}

export default function ProductDetailsModal({
  onSubmit,
  onDismiss,
}: ProductDetailsModalProps) {
  const dispatch = useAppDispatch()
  const isModalVisible = useAppSelector(
    productsIsProductDetailsModalOpenSelector,
  )
  const productHasDetails = useAppSelector(productHasDetailsSelector)
  const selectedProductId = useAppSelector(productSelectedIdSelector)
  const isProductDetailsPending = useAppSelector(productDetailsPendingSelector)

  useEffect(() => {
    if (selectedProductId && isModalVisible) {
      dispatch(fetchProductDetails(selectedProductId))
    }
  }, [selectedProductId, isModalVisible, dispatch])

  return (
    <Modal
      isOpen={isModalVisible}
      onClose={onDismiss}
      className={styles.modal}
      mobileModalMode="bottom-sheet"
      hideClose
    >
      <div className={styles.modalBody}>
        {productHasDetails && !isProductDetailsPending ? (
          <ProductConfigureForm onSubmit={onSubmit} onDismiss={onDismiss} />
        ) : (
          <ProductDetailsModalSkeleton />
        )}
      </div>
    </Modal>
  )
}
