import { createSelector } from '@reduxjs/toolkit'
import {
  CheckoutStatus,
  OrderFormat,
  OrderFormatOption,
  TicketItemStatus,
} from '@ancon/wildcat-types'

import type { RootState } from '../../../store/types'
import { CheckoutOrderStatusState, CheckoutReducerState } from '../types'

function checkoutSelector<K extends keyof CheckoutReducerState>(
  state: RootState,
  key: K,
) {
  return state.checkout[key]
}

export const checkoutCurrentCheckoutOutletIdSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutOutletId')

export const checkoutCurrentCheckoutDetailsSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutDetails')

export const checkoutCurrentCheckoutAddItemPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'checkoutAddItemPending')

export const checkoutIdSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.id

export const checkoutFiltersSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutFilters')

export const checkoutFiltersDeliverySelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.delivery

export const checkoutItemsSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.items || []

export const checkoutItemsCountSelector = (state: RootState) =>
  checkoutItemsSelector(state).length

export const checkoutCurrentCheckoutTotalAmountCurrentSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.totalAmount.currency

export const checkoutCurrentCheckoutIdSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutId')

export const checkoutUpdateItemPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutUpdateItemPending')

export const checkoutUpdatePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutUpdatePending')

export const checkoutCurrentCheckoutSelectedItemIdSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutSelectedCheckoutItemId')

export const checkoutOrderInstructionsSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.instructions

export const checkoutCurrentCheckoutTotalItemCountSelector = createSelector(
  (state: RootState) => checkoutCurrentCheckoutDetailsSelector(state),
  checkout => checkout?.items.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutOutletNameSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.outlet.name

export const checkoutDeletePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutDeletePending')

export const checkoutIsMinimumOrderModalVisibleSelector = (state: RootState) =>
  checkoutSelector(state, 'isMinimumOrderModalVisible')

export const checkoutIsDiscountModalVisibleSelector = (state: RootState) =>
  checkoutSelector(state, 'isDiscountModalVisible')

export const checkoutApplyDiscountPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutApplyDiscountPending')

export const checkoutCurrentCheckoutOutletListItemSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutOutletListDetails')

export const checkoutIsVisibleCustomerInfoModalSelector = (state: RootState) =>
  checkoutSelector(state, 'isCustomerInfoModalVisible')

export const checkoutIsVisibleDeleteOrderModalSelector = (state: RootState) =>
  checkoutSelector(state, 'isVisibleDeleteOrderModal')

export const checkoutStockErrorAdditionalDataSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutStockErrorAdditionalData')

export const checkoutCurrentCheckoutSubTotalSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.subtotalInclTax

export const checkoutCartMinimumDifferenceAmountSelector = (state: RootState) =>
  checkoutCurrentCheckoutDetailsSelector(state)?.minimumOrderDifferenceAmount

export const checkoutItemsByVariantIdSelector = createSelector(
  [checkoutItemsSelector, (_, variantId: string) => variantId],
  (items, variantId) =>
    items.filter(item => item.product.variantId === variantId),
)

export const checkoutItemQuantityByVariantIdSelector = createSelector(
  (state: RootState, productId: string) =>
    checkoutItemsByVariantIdSelector(state, productId),
  checkoutItems => checkoutItems.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutCurrentCheckoutDetailsFetchPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'currentCheckoutDetailsFetchPending')

export const checkoutCurrentCheckoutDetailsOrderFormatSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.orderFormat

export const checkoutCurrentCheckoutDetailsDeliveryAddressSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.delivery?.address

export const checkoutCurrentCheckoutDetailsCustomerUserIdSelector = (
  state: RootState,
) => checkoutCurrentCheckoutDetailsSelector(state)?.customer.userId

export const checkoutItemsQuantitySelector = createSelector(
  (state: RootState) => checkoutCurrentCheckoutDetailsSelector(state),
  checkout => checkout?.items.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutCurrentCheckoutDetailsOutletEnableCustomOrderNotesInAOSelector =
  (state: RootState) =>
    !!checkoutCurrentCheckoutOutletListItemSelector(state)
      ?.enableCustomOrderNotesInAO

export const checkoutCurrentCheckoutUpdatePendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'checkoutUpdatePending')

export const checkoutOutletListItemFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutOutletListItemFetchPending')

export const checkoutAdyenPaymentMethodsFetchPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'adyenPaymentMethodsFetchPending')

export const checkoutPaymentSettingsFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'paymentSettingsFetchPending')

export const checkoutValidatePendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutValidatePending')

export const checkoutIsInitialCurrentCheckoutPendingSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsSelector,
    checkoutCurrentCheckoutDetailsFetchPendingSelector,
    checkoutOutletListItemFetchPendingSelector,
  ],
  (checkoutDetails, checkoutDetailsFetchPending, outletListItemFetchPending) =>
    !checkoutDetails &&
    (checkoutDetailsFetchPending || outletListItemFetchPending),
)

export const checkoutCheckoutDetailsAvailableSelector = (state: RootState) =>
  !!checkoutCurrentCheckoutDetailsSelector(state)

export const checkoutCurrentCheckoutSelectedItemSelector = createSelector(
  [checkoutItemsSelector, checkoutCurrentCheckoutSelectedItemIdSelector],
  (items, selectedCheckoutItemId) =>
    items.find(item => item.id === selectedCheckoutItemId),
)

export const checkoutCurrentCheckoutPendingSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsFetchPendingSelector,
    checkoutCurrentCheckoutAddItemPendingSelector,
    checkoutDeletePendingSelector,
    checkoutUpdateItemPendingSelector,
    checkoutUpdatePendingSelector,
    checkoutValidatePendingSelector,
  ],
  (
    fetchDetailsPending,
    addItemPending,
    deleteCheckoutPending,
    updateItemPending,
    checkoutUpdatePending,
    checkoutValidatePending,
  ) =>
    fetchDetailsPending ||
    addItemPending ||
    deleteCheckoutPending ||
    updateItemPending ||
    checkoutUpdatePending ||
    checkoutValidatePending,
)

export const checkoutFiltersTableNumberSelector = (state: RootState) =>
  checkoutFiltersSelector(state)?.salesLocation?.table?.number

export const checkoutSummarySelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummary')

export const hasCheckoutSummarySelector = (state: RootState) =>
  !!checkoutSummarySelector(state)

export const checkoutSummaryCheckoutIdSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.id

export const checkoutSummaryFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummaryFetchPending')

export const checkoutSummaryAuthorizedAtSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.authorizedAt

export const checkoutSummaryOutletListItemSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutSummaryOutletListItem')

export const checkoutSummaryOutletIdSelector = (state: RootState) =>
  checkoutSummaryOutletListItemSelector(state)?.id

export const checkoutSelectedCheckoutCancelPendingSelector = (
  state: RootState,
) => checkoutSelector(state, 'cancelSelectedCheckoutPending')

export const checkoutSummaryCheckoutStatusSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.status

export const checkoutSummaryCheckoutOrderFormatSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.orderFormat

export const checkoutSummaryCustomersFetchTableOrdersSelector = (
  state: RootState,
) => !!checkoutSummarySelector(state)?.outlet?.customersFetchTableOrders

export const checkoutSummaryReadyItemIdsSelector = (state: RootState) =>
  (checkoutSummarySelector(state)?.items || []).reduce(
    (acc: string[], item) => {
      if (item.ticketItem?.status === TicketItemStatus.Ready) {
        acc.push(item.ticketItem.ticketItemId)
      }
      return acc
    },
    [],
  )

export const checkoutSummaryPartiallyReadyItemCountSelector = (
  state: RootState,
) => checkoutSummaryReadyItemIdsSelector(state)?.length || 0

export const checkoutSummaryPartiallyDoneItemCountSelector = (
  state: RootState,
) =>
  checkoutSummarySelector(state)?.items?.filter(
    item => item?.ticketItem?.status === TicketItemStatus.Closed,
  )?.length || 0

export const checkoutSummaryTotalItemCountSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.items?.length || 0

export const checkoutSummaryCheckoutServiceTimeSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.serviceTime

export const checkoutSummaryCheckoutPreparationTimeSelector = (
  state: RootState,
) => checkoutSummarySelector(state)?.tickets[0]?.preparationTime

export const checkoutSummaryOutletEnablePartiallyPrepareOrdersSelector =
  createSelector(
    [checkoutSummaryOutletListItemSelector],
    outlet => !!outlet?.enablePartiallyPrepareOrders,
  )

export const checkoutWayOfPaymentsFetchPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutWayOfPaymentsFetchPending')

export const currentCheckoutPaymentPendingSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutPaymentPending')

export const checkoutSummaryTicketIdSelector = (state: RootState) =>
  checkoutSummarySelector(state)?.tickets?.[0]?.id

export const checkoutSummaryUseMarkAsPickedUpSelector = createSelector(
  [
    checkoutSummaryCheckoutOrderFormatSelector,
    checkoutSummaryOutletListItemSelector,
  ],
  (checkoutOrderFormat, checkoutOutlet) => {
    if (checkoutOrderFormat && checkoutOutlet) {
      const orderFormatSetting = checkoutOutlet.orderFormatSettings?.find?.(
        settings => settings.orderFormat === checkoutOrderFormat,
      )

      if (!orderFormatSetting) return false

      return (
        (OrderFormatOption.UseMarkAsPickedUp & orderFormatSetting.options) ===
        OrderFormatOption.UseMarkAsPickedUp
      )
    }

    return false
  },
)

export const checkoutItemsByProductIdSelector = createSelector(
  [checkoutItemsSelector, (_, productId: string) => productId],
  (items, productId) => items.filter(item => item.product.id === productId),
)

export const checkoutItemQuantityByProductIdSelector = createSelector(
  (state: RootState, productId: string) =>
    checkoutItemsByProductIdSelector(state, productId),
  checkoutItems => checkoutItems.reduce((acc, item) => acc + item.quantity, 0),
)

export const checkoutSummaryTicketNumberSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.ticketNumber,
)

export const checkoutSummaryOutletNameSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.outlet?.name,
)

export const checkoutSummaryOrderIdSelector = createSelector(
  checkoutSummarySelector,
  checkoutSummary => checkoutSummary?.orderId,
)

// Checkout Order Status Selector
export const checkoutOrderStatusStateSelector = createSelector(
  [
    checkoutSummarySelector,
    checkoutSummaryCheckoutPreparationTimeSelector,
    checkoutSummaryCheckoutServiceTimeSelector,
    checkoutSummaryCheckoutStatusSelector,
    checkoutSummaryCustomersFetchTableOrdersSelector,
    checkoutSummaryOutletEnablePartiallyPrepareOrdersSelector,
  ],
  (
    checkoutSummary,
    preparationTime,
    serviceTime,
    checkoutStatus,
    customersFetchTableOrders,
    enablePartiallyPrepareOrders,
  ): CheckoutOrderStatusState => ({
    checkoutSummary,
    preparationTime,
    serviceTime,
    checkoutStatus,
    customersFetchTableOrders,
    enablePartiallyPrepareOrders,
  }),
)

export const checkoutIsValidCheckoutSelector = createSelector(
  [
    checkoutCurrentCheckoutDetailsOrderFormatSelector,
    checkoutFiltersTableNumberSelector,
    checkoutCurrentCheckoutDetailsDeliveryAddressSelector,
  ],
  (orderFormat, tableNumber, deliveryAddress) => {
    switch (orderFormat) {
      case OrderFormat.TableOrder:
        return !!tableNumber
      case OrderFormat.Delivery:
        return !!deliveryAddress
      case OrderFormat.EatIn:
      case OrderFormat.TakeAway:
        return true
      default:
        return false
    }
  },
)

export const checkoutPaymentAttemptSelector = (state: RootState) =>
  checkoutSelector(state, 'checkoutPaymentAttempt')

export const currentCheckoutStatusSelector = (state: RootState) =>
  checkoutSelector(state, 'currentCheckoutDetails')?.status

export const isWaitingForPaymentSelector = (state: RootState) =>
  currentCheckoutStatusSelector(state) === CheckoutStatus.Received
