import { APIGetRequest, OrderSummary } from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OrderAPI = {
  get: {
    orderStatus: APIGetRequest<{ orderSummary: OrderSummary }>
  }
}

const order: OrderAPI = {
  get: {
    orderStatus: new Request(endpoints.core.orders.orderStatus.byId.details)
      .get,
  },
}

export default order
