import {
  APIGetRequest,
  CustomerOrder,
  CustomerOrderDetails,
  PaginatedResponse,
  PaginationParams,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OrdersAPI = {
  get: {
    list: APIGetRequest<PaginatedResponse<CustomerOrder>, PaginationParams>
    details: APIGetRequest<CustomerOrderDetails, { orderId: string }>
  }
}

const orders: OrdersAPI = {
  get: {
    list: new Request(endpoints.core.customers.orders.list, { version: 1.1 })
      .get,
    details: new Request(endpoints.core.customers.orders.details).get,
  },
}

export default orders
