import clsx from 'clsx'
import {
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form'

import BodyText from './BodyText'
import Input, { InputProps } from './Input'
import FormInputErrorLabel from './FormInputErrorLabel'
import styles from './FormInput.module.scss'

type ReactHookFormRules = UseControllerProps['rules']

interface FormInputProps extends InputProps {
  /* Name of the field in form state */
  fieldName: string
  /* Text label of the input */
  label?: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Customized Error Message */
  renderCustomErrorComponent?: (errorMessage: string) => React.ReactNode
  /* Class name of the container */
  containerClassName?: string
  /* Whether input is required */
  required?: boolean
}

function FormInput({
  fieldName,
  label,
  rules,
  renderCustomErrorComponent,
  containerClassName,
  required,
  ...inputProps
}: FormInputProps) {
  const { setValue } = useFormContext()

  const {
    field: { onBlur, onChange, value },
    fieldState: { error, isTouched },
  } = useController({
    name: fieldName,
    rules,
  })

  const hasError = !!error && isTouched

  function generateErrorText() {
    if (!hasError) return ''

    return `${error.message}`
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value

    // Check whether text only contains whitespace
    if (text && !text.replace(/\s/g, '').length) {
      return
    }

    onChange(text)
    setValue(fieldName, text)
  }

  return (
    <div className={clsx(styles.container, containerClassName)}>
      {label && (
        <BodyText as="label" color="body-1" className={styles.label}>
          {label}
          {required && <span>{' *'}</span>}
        </BodyText>
      )}
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        invalid={hasError}
        className={clsx(!hasError && styles.valid)}
      />
      {hasError ? (
        (renderCustomErrorComponent &&
          renderCustomErrorComponent(generateErrorText())) || (
          <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
        )
      ) : (
        <div className={styles.spacer} />
      )}
    </div>
  )
}

export default FormInput
