import { useCallback, useEffect, useState } from 'react'
import { OutletSearchListItem } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'
import { useDebouncedCallback } from 'use-debounce'
import OutletIcon from '@ancon/wildcat-ui/shared/icons/storefront-outline.svg'

import HeadingText from '../../../../app/components/HeadingText'
import Spinner from '../../../../app/components/Spinner'
import BodyText from '../../../../app/components/BodyText'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { searchOutlets } from '../../../store/outletsThunks'
import { AppRoutes } from '../../../../app/constants'
import { outletsVisibleOutletSearchModalSelector } from '../../../store/outletsSelectors'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import {
  outletsSetOutletsFilters,
  outletsSetVisibleOutletSearchModal,
} from '../../../store/outletsSlice'
import { OutletSearchModalType } from '../../../types'

import OutletSearchResultItem from './OutletSearchResultItem'
import styles from './Suggestions.module.scss'

type OutletSuggestionsProps = {
  query?: string
  hideTitle?: boolean
}

export default function OutletSuggestions({
  query,
  hideTitle = false,
}: OutletSuggestionsProps) {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const [searchPending, setSearchPending] = useState(false)
  const [suggestions, setSuggestions] = useState<OutletSearchListItem[]>([])

  const outletSearchModalVisible = useAppSelector(
    outletsVisibleOutletSearchModalSelector,
  )

  const fetchOutlets = useCallback(
    async (searchTerm: string) => {
      try {
        const response = await dispatch(
          searchOutlets({
            searchTerm,
          }),
        ).unwrap()
        setSuggestions(response.items)
      } catch (err) {
        // TODO: Handle error
      } finally {
        setSearchPending(false)
      }
    },
    [dispatch],
  )

  const debouncedSearch = useDebouncedCallback((term: string) => {
    fetchOutlets(term)
  }, 500)

  useEffect(() => {
    if (query) {
      debouncedSearch(query)
    } else {
      setSearchPending(false)
      debouncedSearch.cancel()
    }

    setSearchPending(!!query)
    setSuggestions([])
  }, [query, debouncedSearch])

  function handleClickAll() {
    dispatch(
      outletsSetOutletsFilters({
        location: undefined,
        searchTerm: query,
      }),
    )

    if (outletSearchModalVisible) {
      dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
    }
  }

  return (
    <div className={styles.sectionContainer}>
      {!hideTitle && (
        <HeadingText as="h3">
          {t('components.outletSearchResultSection.restaurants')}
        </HeadingText>
      )}
      {searchPending && (
        <div className={styles.loaderContainer}>
          <Spinner size="small" />
          <BodyText>
            {t('components.outletSearchResultSection.restaurantLoading')}
          </BodyText>
        </div>
      )}
      {!searchPending && suggestions.length === 0 && (
        <div className={styles.emptyContainer}>
          <BodyText color="body-1">
            {t('components.outletSearchResultSection.noRestaurantsFound')}
          </BodyText>
        </div>
      )}
      {suggestions.slice(0, 3).map(({ name, id, address, urlSlug }) => (
        <OutletSearchResultItem
          url={`/outlet/${urlSlug}`}
          key={id}
          leftAccessory={<OutletIcon />}
        >
          {name}
          <span />
          <p>{address.city}</p>
        </OutletSearchResultItem>
      ))}
      {!searchPending && suggestions.length > 3 && (
        <OutletSearchResultItem
          url={{
            pathname: AppRoutes.Outlets,
            query: { q: query },
          }}
          onClick={handleClickAll}
          leftAccessory={<OutletIcon />}
        >
          {t('components.outletSearchResultSection.allMatchingRestaurants')}
        </OutletSearchResultItem>
      )}
    </div>
  )
}
