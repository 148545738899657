const companyConstraints = Object.freeze({
  pattern: {
    defaultInvoiceReferenceNote: /^[\w\s]+$/,
    swedenBusinessNumber: /^\d+(-\d+)$/,
    zipCode: /^\d{5}(?:-\d{4})?$/,
  },
  max: {
    defaultInvoiceReferenceNote: 50,
    companyName: 60,
    businessNumber: 20,
    street: 100,
    city: 40,
    zipCode: 15,
    contactPersonFirstName: 40,
    contactPersonLastName: 40,
  },
  min: {
    companyName: 4,
    businessNumber: 4,
  },
})

export default companyConstraints
