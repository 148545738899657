import { FormProvider, useForm } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import clsx from 'clsx'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  checkoutItemsSelector,
  checkoutStockErrorAdditionalDataSelector,
} from '../../../checkout/store/checkoutSelectors'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'
import { CheckoutOutOfStockFormState } from '../../types'
import getOutOfStockFormInitialValues from '../../utils/getOutOfStockFormInitialValues'

import CheckoutOutOfStockFormItem from './CheckoutOutOfStockFormItem'
import styles from './CheckoutOutOfStockForm.module.scss'
import CheckoutOutOfStockFormFooter from './CheckoutOutOfStockFormFooter'
import CheckoutOutOfStockBanner from './CheckoutOutOfStockBanner'

type CheckoutOutOfStockFormProps = {
  onCloseClick: () => void
  hideBanner?: boolean
  onSuccess: ({ isCheckoutDeleted }: { isCheckoutDeleted?: boolean }) => void
}

export default function CheckoutOutOfStockForm({
  onCloseClick,
  hideBanner = false,
  onSuccess,
}: CheckoutOutOfStockFormProps) {
  const { t } = useTranslation('common')
  const checkoutStockErrorAdditionalData = useAppSelector(
    checkoutStockErrorAdditionalDataSelector,
  )!
  const checkoutItems = useAppSelector(checkoutItemsSelector)!

  const form = useForm<CheckoutOutOfStockFormState>({
    mode: 'onChange',
    defaultValues: getOutOfStockFormInitialValues(
      checkoutStockErrorAdditionalData,
      checkoutItems,
    ),
  })

  const { watch } = form
  const items = watch('items')

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      {!hideBanner && (
        <div className={styles.titleRow}>
          <CheckoutOutOfStockBanner
            warningText={t('cartOutOfStockModal.notEnoughThisItem', {
              count: checkoutStockErrorAdditionalData.length,
            })}
          />
          <InteractiveDiv className={styles.closeButton} onClick={onCloseClick}>
            <CloseIcon />
          </InteractiveDiv>
        </div>
      )}
      <div
        className={clsx(styles.itemsWrapper, {
          [styles.inCartDrawer]: !hideBanner,
        })}
      >
        {items.map((item, index) => (
          <div key={item.id}>
            <CheckoutOutOfStockFormItem index={index} />
            {index !== items.length - 1 && <hr className={styles.separator} />}
          </div>
        ))}
      </div>
      <CheckoutOutOfStockFormFooter onSuccess={onSuccess} />
    </FormProvider>
  )
}
