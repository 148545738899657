import { Suspense, lazy } from 'react'
import useTranslation from 'next-translate/useTranslation'

import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { showAuthModal } from '../../../../auth/store/authSlice'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import {
  authCustomerIsLoggedInSelector,
  authInitializedSelector,
} from '../../../../auth/store/authSelectors'
import ClientAuthContainer from '../../../../auth/components/ClientAuthContainer'
import Skeleton from '../../../../app/components/Skeleton'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { AppRoutes, NonOrderRoutes } from '../../../../app/constants'
import FillYourInfoModal from '../../../../auth/components/FillYourInfoModal'
import CompanySignUpModal from '../../../../auth/components/CompanySignUpModal'
import { AuthModalType } from '../../../../auth/types'
import useDeviceSize from '../../../../app/hooks/useDeviceSize'

import NavbarUserProfile from './NavbarUserProfile'
import NavLanguageSwitcher from './NavLanguageSwitcher'
import CartButton from './CartButton'
import styles from './NavActions.module.scss'

const AuthModal = lazy(
  () => import('../../../../../features/auth/components/AuthModal'),
)

export default function NavActions() {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  const isLoggedIn = useAppSelector(authCustomerIsLoggedInSelector)
  const isAuthInitialized = useAppSelector(authInitializedSelector)
  const shouldHideCartButton = useRouteMatch([AppRoutes.Checkout])
  const { isMobile } = useDeviceSize()

  const isNonOrderRoute = useRouteMatch(NonOrderRoutes)

  function handleSignInClick() {
    dispatch(showAuthModal(AuthModalType.SignIn))
  }

  return (
    <>
      {!isNonOrderRoute && (
        <div className={styles.actionButtonContainer}>
          {!shouldHideCartButton && (
            <ClientAuthContainer
              skeleton={<Skeleton className={styles.cartBtnSkeleton} />}
            >
              <CartButton />
            </ClientAuthContainer>
          )}
          {!isMobile && (
            <>
              {!!isLoggedIn && <NavbarUserProfile />}
              {/* Prevent showing sign-in button when isLoggedIn = null */}
              {isLoggedIn === false && (
                <Button
                  size="large"
                  variant="tertiary"
                  onClick={handleSignInClick}
                  disabled={!isAuthInitialized}
                  className={styles.signInButton}
                >
                  {t('components.navBar.signIn')}
                </Button>
              )}
            </>
          )}
          <ClientAuthContainer fallbackSpinning={false}>
            <Suspense>
              <AuthModal />
              <FillYourInfoModal />
              <CompanySignUpModal />
            </Suspense>
          </ClientAuthContainer>
        </div>
      )}
      <NavLanguageSwitcher />
    </>
  )
}
