import {
  APIGetRequest,
  DayOfWeek,
  MenuTreeHierarchy,
  OrderFormat,
  SystemVisibility,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type MenuTreesAPI = {
  get: {
    hierarchy: APIGetRequest<
      MenuTreeHierarchy,
      {
        menuTreeId: string
        outletId: string
        dayOfWeek?: DayOfWeek
        orderFormats?: OrderFormat
        purchaseOrderFormat?: OrderFormat
        orderTime?: string
        systemVisibility?: SystemVisibility
      }
    >
  }
}

const menuTrees: MenuTreesAPI = {
  get: {
    hierarchy: new Request(endpoints.core.menuTrees.byId.hierarchy.list, {
      version: 1.1,
    }).get,
  },
}

export default menuTrees
