import {
  ErrorCode,
  MenuGroup,
  MenuTreeHierarchyProductStockCount,
  OrderFormat,
  ProductDetails,
  SystemVisibility,
} from '@ancon/wildcat-types'
import isDetailedProductOutOfStock from '@ancon/wildcat-utils/inventory/isDetailedProductOutOfStock'
import createCodedError from '@ancon/wildcat-utils/error/createCodedError'
import { orderBy } from 'lodash'

import api from '../../../api'
import generateOrderTimeFilters from '../utils/generateOrderTimeFilters'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import {
  outletFiltersSelector,
  outletListItemSelector,
  outletSelectedOutletIdSelector,
} from '../../outlet/store/outletSelector'
import { appShowError } from '../../app/store/appSlice'
import { getTranslatedError } from '../../app/utils/getTranslatedError'
import { ErrorModalType } from '../../app/types'
import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutCurrentCheckoutSelectedItemIdSelector,
} from '../../checkout/store/checkoutSelectors'
import { appLanguageSelector } from '../../app/store/appSelectors'

import { productByIdSelector } from './productSelectors'

export const fetchProductDetails = createAppAsyncThunk<
  {
    productDetails: ProductDetails
    productMenuStocks?: MenuTreeHierarchyProductStockCount[]
  },
  string
>('product/fetchProductDetails', async (productId, { getState, dispatch }) => {
  const checkoutOutletId = checkoutCurrentCheckoutOutletIdSelector(getState())
  const selectedCheckoutItemId = checkoutCurrentCheckoutSelectedItemIdSelector(
    getState(),
  )
  const outletId = outletSelectedOutletIdSelector(getState())
  const outletFilters = outletFiltersSelector(getState())
  const orderFormat = outletFilters.orderFormat!
  const orderDateTime = outletFilters.serviceTime?.time || undefined

  const response = await api.core.products.get.details({
    productId,
    outletId: (selectedCheckoutItemId ? checkoutOutletId : outletId)!,
    systemVisibility: SystemVisibility.OrderWeb,
    orderFormat,
    purchaseOrderFormat: orderFormat,
    ...generateOrderTimeFilters(orderDateTime),
  })

  const productDetails = response?.data!

  const {
    variants,
    hasIngredient,
    ingredients,
    addOnGroups,
    hasAddOnGroups,
    hasVariants,
  } = productDetails

  const organizedProductDetails = {
    ...productDetails,
    ...(hasIngredient && {
      ingredients: orderBy(ingredients, 'position', 'asc'),
    }),
    ...(hasVariants && {
      variants: orderBy(variants, 'position', 'asc'),
    }),
    ...(hasAddOnGroups && {
      addOnGroups: orderBy(addOnGroups, 'position', 'asc').map(addonGroup => ({
        ...addonGroup,
        ingredients: orderBy(addonGroup.ingredients, 'position', 'asc'),
      })),
    }),
  }

  const isProductOutOfStock = isDetailedProductOutOfStock(outletId!, variants)

  if (isProductOutOfStock) {
    const locale = appLanguageSelector(getState())
    const error = await getTranslatedError(
      createCodedError('Out of Stock', ErrorCode.ProductOutOfStock),
      undefined,
      locale,
    )

    dispatch(
      appShowError({
        ...error,
        errorModalType: ErrorModalType.ProductOutOfStock,
      }),
    )

    const productEntity = productByIdSelector(getState(), productId)

    if (productEntity) {
      const productMenuStocks = variants.map(variant => {
        const variantStock = variant.outletStocks[0]

        return {
          stockProductId: variantStock.stockProductId,
          count: variantStock.stockCount,
          reorderCount: variantStock.reorderCount,
          variantId: variant.id,
        }
      })

      return {
        productDetails: organizedProductDetails,
        productMenuStocks,
      }
    }
  }

  return { productDetails }
})

export const fetchOutletMenuGroups = createAppAsyncThunk<MenuGroup[], void>(
  'product/fetchOutletMenuGroups',
  async (_, { getState }) => {
    const outlet = outletListItemSelector(getState())
    const outletFilters = outletFiltersSelector(getState())

    if (outlet) {
      const response = await api.core.menuTrees.get.hierarchy({
        outletId: outlet.id,
        menuTreeId: outlet.mandatoryMenuTreeId,
        orderFormats:
          outlet.showAvailableProductsFromOtherOrderFormats ||
          !outletFilters?.orderFormat
            ? OrderFormat.None
            : outletFilters.orderFormat,
        purchaseOrderFormat: outletFilters?.orderFormat || undefined,
        systemVisibility: SystemVisibility.OrderWeb,
        ...generateOrderTimeFilters(
          outletFilters.serviceTime?.time || undefined,
        ),
      })

      const menuGroups = (response?.data?.menuTreeItems || []).sort(
        (a, b) => a.sortOrder - b.sortOrder,
      )

      return menuGroups
    }

    return []
  },
)
