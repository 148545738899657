'use client'

import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import ClockIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-outline.svg'
import LaterIcon from '@ancon/wildcat-ui/shared/icons/later-outline.svg'
import moment from 'moment'
import { ServiceTimeKindType } from '@ancon/wildcat-types'

import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import {
  outletSetOrderConfigureFilterModal,
  outletSetOutletFilters,
} from '../../../../outlet/store/outletSlice'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { AppRoutes } from '../../../../app/constants'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import {
  outletFiltersServiceTimeSelector,
  outletIsOutletAcceptingPreOrdersSelector,
} from '../../../../outlet/store/outletSelector'
import { OrderConfigureFilterMode } from '../../../../outlet/types'

import NavBarOrderTimeSwitchButton from './NavBarOrderTimeSwitchButton'
import styles from './NavBarOrderTimeSwitch.module.scss'

const OutletFilterEnabledPaths: string[] = [`${AppRoutes.Outlet}/`]

type NavBarOrderTimeSwitchProps = {
  isInNavBar?: boolean
}

export default function NavBarOrderTimeSwitch({
  isInNavBar = true,
}: NavBarOrderTimeSwitchProps) {
  const dispatch = useAppDispatch()

  const { t } = useTranslation('common')

  const isOutletFiltersEnabled = useRouteMatch(OutletFilterEnabledPaths)

  const serviceTime = useAppSelector(outletFiltersServiceTimeSelector)
  const isOutletAcceptingPreOrders = useAppSelector(
    outletIsOutletAcceptingPreOrdersSelector,
  )

  function handleOnClick(type: ServiceTimeKindType) {
    switch (type) {
      case ServiceTimeKindType.AtSpecifiedTime:
        dispatch(
          outletSetOrderConfigureFilterModal(
            OrderConfigureFilterMode.OrderTime,
          ),
        )
        break

      case ServiceTimeKindType.AsSoonAsPossible:
      default:
        dispatch(
          outletSetOutletFilters({
            serviceTime: { kind: type, time: '' },
          }),
        )
    }
  }

  if (!isOutletFiltersEnabled) {
    return null
  }

  function getServiceTimeText() {
    return serviceTime?.time
      ? moment(serviceTime.time).calendar({
          sameDay: `[${t('today')}], LT`,
          nextDay: `[${t('tomorrow')}] LT`,
          nextWeek: 'ddd LT',
          sameElse: 'ddd LT',
        })
      : t('orderTimeSwitch.later')
  }

  return (
    <div className={clsx(styles.container, isInNavBar && styles.navBar)}>
      <NavBarOrderTimeSwitchButton
        type={ServiceTimeKindType.AsSoonAsPossible}
        onClick={handleOnClick}
        isInNavBar={isInNavBar}
        disabled={isOutletAcceptingPreOrders}
      >
        <ClockIcon />
        <span>{t('orderTimeSwitch.orderNow')}</span>
      </NavBarOrderTimeSwitchButton>
      <NavBarOrderTimeSwitchButton
        type={ServiceTimeKindType.AtSpecifiedTime}
        onClick={handleOnClick}
        isInNavBar={isInNavBar}
      >
        <LaterIcon />
        <span className={styles.capitalize}>{getServiceTimeText()}</span>
      </NavBarOrderTimeSwitchButton>
    </div>
  )
}
