import { useRouter } from 'next/router'
import Image from 'next/image'
import clsx from 'clsx'
import HamburgerIcon from '@ancon/wildcat-ui/shared/icons/menu.svg'

import Container from '../../container/Container'
import OutletSearchModal from '../../../../outlets/components/searchOutlets/OutletSearchModal'
import OrderFormatSelectionModal from '../../../../outlets/components/OrderFormatSelectionModal'
import ClientAuthContainer from '../../../../auth/components/ClientAuthContainer'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { clientContextCustomerIdSelector } from '../../../../clientContext/store/clientContextSelectors'
import CustomerEvents from '../../../../../events/CustomerEvents'
import OrderProduct from '../../../../product/components/OrderProduct'
import DeleteOrderModal from '../../../../checkout/components/DeleteOrderModal'
import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import { appSetIsMobileDrawerVisible } from '../../../../app/store/appSlice'
import GuestCustomerOnboardingModal from '../../../../checkout/components/guestCustomerOnboardingModal/GuestCustomerOnboardingModal'
import { Toaster } from '../../../../notification'
import SwitchLanguageModal from '../../../../app/components/switchLanguageModal/SwitchLanguageModal'
import LocationPermissionModal from '../../../../location/components/LocationPermissionModal'
import useRouteMatch from '../../../../app/hooks/useRouteMatch'
import { NonOrderRoutes } from '../../../../app/constants'
import InteractiveDiv from '../../InteractiveDiv'
import ErrorModal from '../../../../app/components/errorModal/ErrorModal'
import isAppWhiteLabeled from '../../../../app/utils/isAppWhiteLabeled'
import getHeaderLogoPath from '../../../../app/utils/getHeaderLogoPath'
import OneSignalInitializer from '../../../../notification/components/OneSignalInitializer'

import styles from './MainNavbar.module.scss'
import NavActions from './NavActions'
import NavbarOutletSearch from './NavbarOutletSearch'
import NavbarOrderFormatFilter from './NavbarOrderFormatFilter'
import NavBarOrderTimeSwitch from './NavBarOrderTimeSwitch'

function MainNavbar() {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const customerId = useAppSelector(clientContextCustomerIdSelector)
  const isWhiteLabeled = isAppWhiteLabeled()

  const isNonOrderRoute = useRouteMatch(NonOrderRoutes)

  function handleNavigateToHome() {
    router.push('/')
  }

  function handleOpenMobileNavBar() {
    dispatch(appSetIsMobileDrawerVisible(true))
  }

  const headerLogoPath = getHeaderLogoPath()

  return (
    <nav
      className={clsx(styles.navContainer, {
        [styles.whiteLabeled]: isWhiteLabeled,
      })}
    >
      <Container fluid>
        <div className={styles.navLeftContainer}>
          {!isNonOrderRoute && (
            <Button
              className={styles.navToggle}
              onClick={handleOpenMobileNavBar}
            >
              <HamburgerIcon />
            </Button>
          )}
          {!isAppWhiteLabeled() && (
            <InteractiveDiv
              className={styles.navBrand}
              onClick={handleNavigateToHome}
            >
              <Image
                height={40}
                width={176}
                alt="logo"
                src={headerLogoPath}
                priority
              />
            </InteractiveDiv>
          )}
          {!isNonOrderRoute && (
            <ClientAuthContainer fallbackSpinning={false}>
              <div className={styles.actionButtonWrapper}>
                <NavbarOutletSearch />
                <NavbarOrderFormatFilter />
                <NavBarOrderTimeSwitch />
              </div>
              {/* Modals */}
              <OutletSearchModal />
              <OrderFormatSelectionModal />
              <OrderProduct />
              <DeleteOrderModal />
              <GuestCustomerOnboardingModal />
              <SwitchLanguageModal />
              <LocationPermissionModal />
              <ErrorModal />
              {/* Popups */}
              <Toaster />
              {/* SignalR Listeners */}
              {customerId && <CustomerEvents />}
              {/* OneSignal */}
              {customerId && <OneSignalInitializer />}
            </ClientAuthContainer>
          )}
        </div>
        {isAppWhiteLabeled() && (
          <div className={styles.navMiddleContainer}>
            <InteractiveDiv
              className={styles.navBrand}
              onClick={handleNavigateToHome}
            >
              <Image
                height={40}
                width={176}
                alt="logo"
                src={headerLogoPath}
                priority
              />
            </InteractiveDiv>
          </div>
        )}
        <div className={styles.navRightContainer}>
          <div className={styles.navActions}>
            <NavActions />
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default MainNavbar
