import OneSignal from 'react-onesignal'
import { MessagingProviderType } from '@ancon/wildcat-types'
import getTranslation from 'next-translate/getT'

import api from '../../../api'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import getOneSignalAppId from '../../app/utils/getOneSignalAppId'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { clientContextCustomerSelector } from '../../clientContext/store/clientContextSelectors'
import { fetchCustomerDetails } from '../../clientContext/store/clientContextThunks'

export const initializeOneSignal = createAppAsyncThunk<
  void,
  string | undefined
>(
  'notification/initializeOneSignal',
  async (customerId, { getState, dispatch }) => {
    const locale = appLanguageSelector(getState())
    const t = await getTranslation(locale, 'common')

    const appId = getOneSignalAppId()

    if (appId) {
      await OneSignal.init({
        appId,
        subdomainName: process.env.NEXT_PUBLIC_ONESIGNAL_SUBDOMAIN_NAME,
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: 'push',
                autoPrompt: false,
                text: {
                  /* limited to 90 characters */
                  actionMessage: t('notification.permissionDialog.message'),
                  /* limited to 15 characters */
                  acceptButton: t('notification.permissionDialog.acceptButton'),
                  /* limited to 15 characters */
                  cancelButton: t('notification.permissionDialog.cancelButton'),
                },
              },
            ],
          },
        },
      })
    }

    const userDetails = clientContextCustomerSelector(getState())

    if (!!customerId && userDetails?.id !== customerId) {
      dispatch(fetchCustomerDetails(customerId))
    }
  },
)

export const promptForPushNotificationsWithUserResponse = createAppAsyncThunk(
  'notification/promptForPushNotificationsWithUserResponse',
  async () => {
    await OneSignal.showSlidedownPrompt()
  },
)

export const createOrUpdateMessagingProvider = createAppAsyncThunk<
  void,
  string | undefined
>(
  'notification/createOrUpdateMessagingProvider',
  async (oneSignalUserId, { getState, dispatch }) => {
    const appId = getOneSignalAppId()

    const userDetails = clientContextCustomerSelector(getState())

    if (userDetails) {
      const existingProvider = userDetails?.messagingProviders?.find?.(
        provider => provider.appId === appId,
      )

      if (
        existingProvider &&
        existingProvider.externalIdentifier !== oneSignalUserId
      ) {
        await api.user.messagingProviders.put.update(
          {
            externalIdentifier: oneSignalUserId || null,
          },
          {
            customerId: userDetails.id,
            messagingProviderId: existingProvider.id,
          },
        )

        dispatch(fetchCustomerDetails(userDetails.id))
      } else if (!existingProvider && oneSignalUserId) {
        await api.user.messagingProviders.post.create(
          {
            externalIdentifier: oneSignalUserId,
            type: MessagingProviderType.OneSignal,
          },
          {
            customerId: userDetails.id,
          },
        )

        dispatch(fetchCustomerDetails(userDetails.id))
      }
    }
  },
)
