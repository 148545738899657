import {
  CheckoutDetails,
  CheckoutStatus,
  CustomerOrder,
  CustomerOrderDetails,
  OrderFormat,
  OrderSummary,
  PaginatedResponse,
  TicketItemStatus,
} from '@ancon/wildcat-types'
import getT from 'next-translate/getT'
import moment from 'moment'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import api from '../../../api'
import { getTranslatedError } from '../../app/utils/getTranslatedError'
import { appShowError } from '../../app/store/appSlice'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { defaultPaginatedResponse } from '../../outlets/constants'
import { clientContextCustomerIdSelector } from '../../clientContext/store/clientContextSelectors'
import { Toast } from '../../notification'
import getTenantIds from '../../app/utils/getTenantIds'

export const markOrderAsPickedUp = createAppAsyncThunk<
  void,
  { outletId: string; ticketId: string }
>(
  'orders/markOrderAsPickedUp',
  async ({ outletId, ticketId }, { dispatch, getState }) => {
    try {
      await api.core.tickets.put.byId.markAsPickedUp(undefined, {
        outletId,
        ticketId,
      })
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getT(locale, 'common')
      const error = await getTranslatedError(
        err,
        {
          title: t('errors.markAsPickedUpFailure.title'),
          message: t('errors.markAsPickedUpFailure.message'),
        },
        locale,
      )

      dispatch(appShowError(error))
    }
  },
)

export const markTicketItemAsPickedUp = createAppAsyncThunk<
  void,
  { outletId: string; ticketId: string; ticketItemIds: string[] }
>(
  'orders/markTicketItemAsPickedUp',
  async ({ outletId, ticketId, ticketItemIds }, { dispatch, getState }) => {
    try {
      await api.core.tickets.put.byId.ticketItemsStatus(
        {
          ticketItemIds,
          ticketItemStatus: TicketItemStatus.Closed,
        },
        {
          outletId,
          ticketId,
        },
      )
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getT(locale, 'common')
      const error = await getTranslatedError(
        err,
        {
          title: t('errors.markAsPickedUpFailure.title'),
          message: t('errors.markAsPickedUpFailure.message'),
        },
        locale,
      )

      dispatch(appShowError(error))
    }
  },
)

export const sendOrderReceipt = createAppAsyncThunk<
  void,
  { orderId: string; email: string }
>('orders/sendOrderReceipt', async ({ orderId, email }, { getState }) => {
  const locale = appLanguageSelector(getState())
  const t = await getT(locale, 'common')
  try {
    await api.core.orderReceipt.post.send({ email }, { orderId })
    Toast.success(
      t('components.toasts.receiptSent.title'),
      t('components.toasts.receiptSent.message', { email }),
    )
  } catch (err) {
    Toast.error(
      t('components.toasts.receiptSentError.title'),
      t('components.toasts.receiptSentError.message', { email }),
    )
    throw err
  }
})

export const downloadOrderReceipt = createAppAsyncThunk<Blob, string>(
  'orders/downloadOrderReceipt',
  async orderId => {
    const response = await api.core.orderReceipt.get.download(
      { orderId },
      { responseType: 'blob' },
    )

    return response.data
  },
)

export const fetchActiveOrders = createAppAsyncThunk<
  PaginatedResponse<CheckoutDetails>,
  { limit?: number; offset?: number }
>('checkout/fetchActiveOrders', async ({ offset = 0, limit }, { getState }) => {
  const CheckoutsFrom = moment().subtract(3, 'hours').toISOString()
  const CheckoutsTo = moment().add(3, 'hours').toISOString()
  const customerId = clientContextCustomerIdSelector(getState())
  const tenantIds = getTenantIds()

  if (customerId) {
    const response = await api.core.checkouts.get.checkouts({
      limit,
      offset,
      filter: {
        createdAt: [`gt:${CheckoutsFrom}`, `lt:${CheckoutsTo}`],
        status: [
          `in: ${CheckoutStatus.Created}`,
          `in: ${CheckoutStatus.Ready}`,
          `in: ${CheckoutStatus.Authorized}`,
          `in: ${CheckoutStatus.Received}`,
          `in: ${CheckoutStatus.Captured}`,
          `in: ${CheckoutStatus.InProgress}`,
          `in: ${CheckoutStatus.ReadyToCollect}`,
        ],
        orderformat: [
          `in:${OrderFormat.EatIn}`,
          `in:${OrderFormat.Delivery}`,
          `in:${OrderFormat.TakeAway}`,
          `in:${OrderFormat.TableOrder}`,
        ],
        ...(tenantIds?.length && {
          'tenant.id': tenantIds.map((id: string) => `in:${id}`),
        }),
      },
    })
    return response.data
  }

  return defaultPaginatedResponse
})

export const fetchOrdersList = createAppAsyncThunk<
  PaginatedResponse<CustomerOrder>,
  { offset?: number; limit?: number }
>('orders/fetchOrdersList', async ({ offset = 0, limit }) => {
  const tenantIds = getTenantIds()

  const response = await api.core.orders.get.list({
    order: 'createdAt:desc',
    limit,
    offset,
    ...(tenantIds?.length && {
      filter: {
        'tenant.id': tenantIds.map(id => `in:${id}`),
      },
    }),
  })
  return response.data
})

export const fetchOrderDetails = createAppAsyncThunk<
  CustomerOrderDetails,
  string
>('orders/fetchOrderDetails', async orderId => {
  const response = await api.core.orders.get.details({ orderId })
  return response.data
})

export const fetchOrderSummary = createAppAsyncThunk<OrderSummary, string>(
  'orders/fetchOrderSummary',
  async incrementalId => {
    const response = await api.core.order.get.orderStatus({
      incrementalId,
    })

    return response.data?.orderSummary
  },
)
