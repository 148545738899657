import config from '../../config/generated.json'

function getOneSignalAppId(): string | null {
  if (process.env.NODE_ENV === 'development') {
    return null
  }

  return config.envConfig.oneSignalAppId
}

export default getOneSignalAppId
