import config from '../../config/generated.json'

export default function getTenantIds(includeTestTenants = true) {
  const tenantIds: string[] = [
    ...config.tenantIds[
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
        ? 'production'
        : 'staging'
    ],
  ]

  if (includeTestTenants) {
    tenantIds.push(
      ...config.tenantIds[
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
          ? 'productionTesting'
          : 'stagingTesting'
      ],
    )
  }

  return tenantIds
}
