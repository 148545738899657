import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { CountryCode } from '@ancon/wildcat-types'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'
import noop from 'lodash/noop'

import { CompanySignUpFormState } from '../types'
import useAppSelector from '../../../store/hooks/useAppSelector'
import { clientContextCustomerDetailsSelector } from '../../clientContext/store/clientContextSelectors'
import companyConstraints from '../../company/constraints/companyConstraints'
import FormInput from '../../app/components/FormInput'
import FormDropdown from '../../app/components/FormDropdown'
import FormPhoneNumberInput from '../../app/components/FormPhoneNumberInput'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { registerAsCompanyUser } from '../store/authThunks'
import { setAuthCompanySignUpModalVisible } from '../store/authSlice'
import HeadingText from '../../app/components/HeadingText'

import LoginFormInputs from './LoginFormInputs'
import styles from './CompanySignUpForm.module.scss'

export default function CompanySignUpForm() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const customer = useAppSelector(clientContextCustomerDetailsSelector)

  const countryOptions = useMemo(
    () =>
      [CountryCode.Sweden, CountryCode.Norway].map(country => ({
        label: t(`companySignUpModal.country.${country}`),
        value: country,
      })),
    [t],
  )

  const form = useForm<CompanySignUpFormState>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      businessNumber: '',
      email: '',
      password: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      telephoneNumber: customer?.telephoneNumber || '',
    },
  })

  const {
    formState: { isSubmitting, isValid, isDirty },
    handleSubmit,
    watch,
  } = form

  const countryCode = watch('country')

  async function handleSignUp(values: CompanySignUpFormState) {
    await dispatch(registerAsCompanyUser(values))
      .unwrap()
      .then(() => {
        dispatch(setAuthCompanySignUpModalVisible(false))
      })
      .catch(noop)
  }

  function validateBusinessNumber(value: string) {
    if (countryCode === CountryCode.Sweden) {
      const isValidNumber =
        companyConstraints.pattern.swedenBusinessNumber.test(value)
      return !value || isValidNumber || t('validations.invalid')
    }
    return true
  }

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...form}
    >
      <div className={styles.wrapper}>
        <section>
          <HeadingText as="h3" className={styles.sectionTitle}>
            {t('companySignUpModal.companyDetails')}
          </HeadingText>
          <div className={styles.row}>
            <FormInput
              fieldName="companyName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.companyName')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.companyName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.companyName,
                  }),
                },
                minLength: {
                  value: companyConstraints.min.companyName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.min.companyName,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourCompanyNameHere')}
            />
            <FormInput
              fieldName="businessNumber"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.businessNumber')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.businessNumber,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.businessNumber,
                  }),
                },
                minLength: {
                  value: companyConstraints.min.businessNumber,
                  message: t('validations.minLength', {
                    limit: companyConstraints.min.businessNumber,
                  }),
                },
                validate: validateBusinessNumber,
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourBusinessNumberHere')}
            />
          </div>
          <div className={styles.row}>
            <LoginFormInputs inputContainerClassName={styles.inputContainer} />
          </div>
        </section>
        <section>
          <HeadingText as="h3" className={styles.sectionTitle}>
            {t('companySignUpModal.invoicingAddress')}
          </HeadingText>
          <div className={styles.row}>
            <FormInput
              fieldName="street"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.street')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.street,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.street,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourStreetHere')}
            />
          </div>
          <div className={styles.row}>
            <FormInput
              fieldName="zipCode"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.zipCode')}
              type="text"
              rules={{
                required: t('validations.required'),
                pattern: {
                  message: t('validations.invalid'),
                  value: companyConstraints.pattern.zipCode,
                },
                maxLength: {
                  value: companyConstraints.max.zipCode,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.zipCode,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourZipCodeHere')}
            />
            <FormInput
              fieldName="city"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.city')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.city,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.city,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourCityHere')}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.dropdownWrapper}>
              <FormDropdown
                fieldName="country"
                label={t('companySignUpModal.country.country')}
                options={countryOptions}
              />
            </div>
            <div className={styles.spaceCol} />
          </div>
        </section>
        <section>
          <HeadingText as="h3" className={styles.sectionTitle}>
            {t('companySignUpModal.contactPersonDetails')}
          </HeadingText>
          <div className={styles.row}>
            <FormInput
              fieldName="firstName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.firstName')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.contactPersonFirstName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.contactPersonFirstName,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourFirstNameHere')}
            />
            <FormInput
              fieldName="lastName"
              autoComplete="off"
              autoCapitalize="none"
              label={t('companySignUpModal.lastName')}
              type="text"
              rules={{
                required: t('validations.required'),
                maxLength: {
                  value: companyConstraints.max.contactPersonLastName,
                  message: t('validations.maxLength', {
                    limit: companyConstraints.max.contactPersonLastName,
                  }),
                },
              }}
              readOnly={isSubmitting}
              containerClassName={styles.inputContainer}
              required
              placeholder={t('companySignUpModal.yourLastNameHere')}
            />
          </div>
          <div>
            <FormPhoneNumberInput
              autoComplete="off"
              fieldName="telephoneNumber"
              label={t('companySignUpModal.phoneNumber')}
              readOnly={isSubmitting}
              rules={{
                required: t('validations.required'),
              }}
              required
              placeholder={t('companySignUpModal.yourPhoneNumberHere')}
            />
          </div>
        </section>
      </div>
      <div className={styles.footer}>
        <Button
          onClick={handleSubmit(handleSignUp)}
          loading={isSubmitting}
          disabled={!isValid || !isDirty || isSubmitting}
          size="large"
        >
          {t('companySignUpModal.signUpAsCompany')}
        </Button>
      </div>
    </FormProvider>
  )
}
