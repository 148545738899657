import { createSlice } from '@reduxjs/toolkit'

import { ProductReducerState } from '../types'
import extractProductsFromMenuTree from '../utils/extractProductsFromMenuTree'

import productReducers from './productReducers'
import { fetchOutletMenuGroups, fetchProductDetails } from './productThunks'
import productEntityAdapter from './productEntityAdapter'

const productInitialState: ProductReducerState =
  productEntityAdapter.getInitialState({
    selectedProductId: null,
    productDetails: null,

    fetchProductDetailsPending: false,
    menuGroupsFetchPending: false,

    isProductDetailsModalOpen: false,
    isUpsellGroupModalOpen: false,
  })

const productSlice = createSlice({
  name: 'product',
  initialState: productInitialState,
  reducers: productReducers,
  extraReducers(builder) {
    // Fetch product details
    builder.addCase(fetchProductDetails.pending, state => {
      state.fetchProductDetailsPending = true
    })
    builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
      state.fetchProductDetailsPending = false
      if (state.isProductDetailsModalOpen) {
        if (action.payload) {
          const { productMenuStocks, productDetails } = action.payload
          state.productDetails = productDetails

          if (productMenuStocks && productDetails) {
            const { productId } = productDetails

            productEntityAdapter.updateOne(state, {
              id: productId,
              changes: {
                productStockCounts: productMenuStocks,
              },
            })
          }
        }
      }
    })
    builder.addCase(fetchProductDetails.rejected, state => {
      state.fetchProductDetailsPending = false
    })
    // Fetch menu
    builder.addCase(fetchOutletMenuGroups.pending, state => {
      state.menuGroupsFetchPending = true
      productEntityAdapter.removeAll(state)
    })
    builder.addCase(fetchOutletMenuGroups.fulfilled, (state, action) => {
      state.menuGroupsFetchPending = false
      productEntityAdapter.setAll(
        state,
        extractProductsFromMenuTree(action.payload),
      )
    })
    builder.addCase(fetchOutletMenuGroups.rejected, state => {
      state.menuGroupsFetchPending = false
    })
  },
})

const productReducer = productSlice.reducer

export const {
  setSelectedProductId,
  setProductUpsellModalVisible,
  setProductDetailsModalVisible,
} = productSlice.actions

export default productReducer
