import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import BinIcon from '@ancon/wildcat-ui/shared/icons/trash-bin-outline.svg'
import OrderTypeIcon from '@ancon/wildcat-ui/web/orderweb/OrderTypeIcon'
import { useRouter } from 'next/router'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import {
  checkoutCurrentCheckoutDetailsSelector,
  checkoutCurrentCheckoutPendingSelector,
  checkoutDeletePendingSelector,
  checkoutItemsQuantitySelector,
  checkoutStockErrorAdditionalDataSelector,
} from '../../store/checkoutSelectors'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsCartDrawerVisible } from '../../../app/store/appSlice'
import Spinner from '../../../app/components/Spinner'
import MinimumOrderModal from '../MinimumOrderModal'
import {
  checkoutSetIsVisibleDeleteOrderModal,
  checkoutSetIsVisibleMinimumOrderModal,
} from '../../store/checkoutSlice'
import { AppRoutes } from '../../../app/constants'
import { orderFormatTextMap } from '../../../outlets/constants'
import ModalCard from '../../../app/components/modal/ModalCard'
import CheckoutOutOfStockForm from '../../../inventory/components/checkoutOutOfStock/CheckoutOutOfStockForm'
import { updateCheckout } from '../../store/checkoutThunks'
import InteractiveDiv from '../../../layout/components/InteractiveDiv'

import styles from './ShoppingCart.module.scss'
import CartItem from './CartItem'
import CartTotal from './CartTotal'

export default function ShoppingCart() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const checkout = useAppSelector(checkoutCurrentCheckoutDetailsSelector)
  const checkoutItemsCount = useAppSelector(checkoutItemsQuantitySelector)
  const checkoutPending = useAppSelector(checkoutCurrentCheckoutPendingSelector)
  const checkoutDeletePending = useAppSelector(checkoutDeletePendingSelector)
  const checkoutStockErrorAdditionalData = useAppSelector(
    checkoutStockErrorAdditionalDataSelector,
  )
  const [isShowStockPopup, setIsShowStockPopup] = useState(false)
  const [isCheckoutUpdatePending, setIsCheckoutUpdatePending] = useState(false)
  const { outlet, items, minimumOrderDifferenceAmount, orderFormat } = checkout!

  function handleDrawerCloseClick() {
    dispatch(appSetIsCartDrawerVisible(false))
  }

  async function handleOnClickGoToCheckout() {
    if (
      minimumOrderDifferenceAmount &&
      minimumOrderDifferenceAmount?.amount > 0
    ) {
      dispatch(checkoutSetIsVisibleMinimumOrderModal(true))
    } else {
      setIsCheckoutUpdatePending(true)
      await dispatch(updateCheckout({}))
        .unwrap()
        .then(() => {
          router.push({ pathname: AppRoutes.Checkout })
        })
        .catch(err => {
          if (err?.stockErrorAdditionalData) {
            setIsShowStockPopup(true)
          }
        })
        .finally(() => {
          setIsCheckoutUpdatePending(false)
        })
    }
  }

  function handleOnClickDeleteOrder() {
    dispatch(checkoutSetIsVisibleDeleteOrderModal(true))
  }

  function handleOnCloseStockPopup() {
    setIsShowStockPopup(false)
  }

  function handleOnSuccessStockPopup({
    isCheckoutDeleted,
  }: {
    isCheckoutDeleted?: boolean
  }) {
    setIsShowStockPopup(false)
    if (!isCheckoutDeleted) {
      router.push({ pathname: AppRoutes.Checkout })
    }
  }

  useEffect(() => {
    if (checkoutStockErrorAdditionalData) {
      setIsShowStockPopup(true)
    }
  }, [checkoutStockErrorAdditionalData])

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerDetails}>
          <div>
            <div className={styles.topContainer}>
              <HeadingText as="h2">{t('components.order.myOrder')}</HeadingText>
              {checkoutPending && <Spinner size="medium" />}
            </div>
            <BodyText color="body-2">{outlet.name}</BodyText>
            <div className={styles.orderFormatRow}>
              <OrderTypeIcon orderFormat={orderFormat!} />
              {orderFormat && (
                <BodyText as="span" className={styles.orderFormatText}>
                  {t(orderFormatTextMap[orderFormat as never])}
                </BodyText>
              )}
            </div>
          </div>
          <Button variant="secondary" onClick={handleDrawerCloseClick}>
            <CloseIcon />
          </Button>
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {items.map((item, index) => (
          <CartItem
            cartItem={item}
            key={item.id}
            showSeparator={index !== items.length - 1}
          />
        ))}
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footerTotalRow}>
          <div className={styles.footerTotalCount}>
            <BodyText fontSize="1.6rem" color="body-3">
              {checkoutItemsCount}
            </BodyText>
          </div>
          <div className={styles.footerTotalBreakdown}>
            <CartTotal checkout={checkout!} />
          </div>
        </div>
        <Button
          variant="primary"
          className={styles.checkoutButton}
          onClick={handleOnClickGoToCheckout}
          disabled={checkoutPending || checkoutDeletePending}
          loading={isCheckoutUpdatePending}
        >
          {t('components.order.goToCheckout')}
        </Button>
        <Button
          variant="secondary"
          className={styles.orderDeleteButton}
          disabled={checkoutPending || checkoutDeletePending}
          onClick={handleOnClickDeleteOrder}
        >
          <BinIcon />
          {t('deleteOrderModal.title')}
        </Button>
      </div>
      {isShowStockPopup && checkoutStockErrorAdditionalData && (
        <div>
          <InteractiveDiv
            className={styles.backdrop}
            onClick={handleOnCloseStockPopup}
          />
          <ModalCard className={styles.outOfStockModal}>
            <CheckoutOutOfStockForm
              onCloseClick={handleOnCloseStockPopup}
              onSuccess={handleOnSuccessStockPopup}
            />
          </ModalCard>
        </div>
      )}
      {minimumOrderDifferenceAmount && <MinimumOrderModal />}
    </div>
  )
}
